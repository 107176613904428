.collapse-container {
    $container: &;

    margin: 0 -25px;
    padding: 26px 25px;
    transition: .5s;
    border-bottom: 1px solid #DDD;
    margin-bottom: 0;
    cursor: pointer;
    background: url('../img/chevron-down.svg') calc(100% - 30px) 27px no-repeat;
    background-size: 10px auto;

    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;

    &__normal-selector {
        -moz-transition: .5s;
        -ms-transition: .5s;
        -o-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
        max-height: 0;
        opacity: 0;
    }

    &__custom-selector {
        margin-top: 0;
        -moz-transition: .5s;
        -ms-transition: .5s;
        -o-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0;

        > li {
            display: block;
            width: 100%;
            float: none;
            padding: 0;
            margin-bottom: 1px;

            button {
                @include appearance-off();
                background-color: #FFF;
                display: block;
                width: 100%;
                text-align: left;
                border: none;
                padding: 19px 12px;

                @media (hover:hover) {
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    transition: all .3s ease;

                    &:hover {
                        padding-left: 20px;
                    }
                }
            }

            &:first-child {
                button {
                    @include border-radius(12px 12px 0 0);
                }
            }

            &:last-child {
                button {
                    @include border-radius(0 0 12px 12px);
                }
            }
        }
    }

    &--active {
        background-color: #EEE;
        background-image: url('../img/chevron-up.svg');
        cursor: default;

        #{$container}__normal-selector {
            overflow: hidden;
            max-height: 40px;
            opacity: 1;
        }

        #{$container}__custom-selector {
            display: block;
            margin-top: 10px;
            max-height: 1700px;
        }
    }

    &--answered {
        label span {
            font-size: 18px;
            font-weight: 400;
            color: #0B3;
        }
    }

    label {
        font-size: 20px;
        margin-bottom: 0;
        line-height: 1;
        cursor: pointer;
    }
}
