.registration-modal {
    width: 100%;

    @media(min-width: 768px) {
        width: 550px;
    }

    .registration-steps {
        position: relative;
    }
}

mp-terms-of-service,
mp-personal-info,
mp-life-info,
mp-user-profile-photo,
mp-appearance,
mp-last-details,
mp-confirm-email {
    display: block;
}
