.rules-modal {
    .close-button {
        float: right;
        background: transparent;
        border: 0;
        color: #BBB;
        font-size: 25px;
        line-height: 1;
        margin: 5px -5px 25px 25px;
        padding: 0;

        i {
            display: block;
        }
    }

    .complete-registration {
        z-index: 1040;
    }

    &.dial-box {
        padding: 20px 30px;
        padding: calc(20px + env(safe-area-inset-top)) calc(30px + env(safe-area-inset-right)) calc(20px + env(safe-area-inset-bottom)) calc(30px + env(safe-area-inset-left));
    }

    h2 {
        margin-top: 0;
        font-size: 20px;
        line-height: 24px;
    }

    p {
        margin-top: 10px;
        font-size: 12px;
        line-height: 22px;
        color: #666;
        text-align: left;

        &.subtext {
            font-size: 16px;
            color: inherit;
        }
    }

    dl {
        margin: 0 -10px;
        font-size: 16px;
        line-height: 22px;
    }

    dt {
        position: relative;
        margin-top: 41px;

        label {
            color: inherit !important;
            font-size: inherit !important;
            line-height: inherit !important;
            margin-bottom: 0 !important;
            padding-left: 37px !important;
        }

        .required {
            position: absolute;
            bottom: 100%;
            right: 0;
            font-size: 11px;
            line-height: 16px;
            color: #FFF;
            background: $fleur-de-lis;
            padding: 0 10px;
        }
    }

    dd {
        padding-left: 37px;
    }
}
