@import 'apps/prince/assets/sass/config.scss';

mp-actionbar-profile-details {
    width: 100%;

    .actionbar-profile-details {
        display: flex;
        align-items: center;

        a {
            margin-right: 10px;
        }
    }

    .avatar-link {
        position: relative;
    }

    .online-tip {
        position: absolute;
        background-color: var(--sucess2);
        right: 0;
        color: transparent;
        overflow: hidden;
        border: 2px solid var(--ui1);
        width: 9px;
        height: 9px;
        pointer-events: none;
    }
}

.action-message-receive {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;

    i.material-icons {
        color: var(--radRed);
        font-size: 18px;
        padding-right: 5px;
        line-height: 0;
        position: relative;
        top: 3px;
    }

    &__profile {
        white-space: nowrap;
        font-weight: 700;
        @include truncate-singleline(calc(100% - 10px));

        &__username {
            color: var(--ui7);
        }
    }

    &__location {
        font-size: 12px;
        color: var(--ui7);
        white-space: nowrap;
    }
}

mp-actionbar-page-title, mp-actionbar-back-title {
    width: 100%;
}

.actioonbar-profile-edit {
    justify-content: space-between;

    mp-actionbar-logo-mp {
        margin: 0 15px;
    }
}

.actionbar-borderless {
    border-width: 0;
    background-color: transparent;
    &:hover {
        border-width: 0;
        background-color: transparent;
    }
}
