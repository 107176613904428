.boost-profile-bubbles {
    &__bubbles-list {
        display: flex;
        max-width: 390px;
        justify-content: space-around;
        margin: auto;
        padding: 30px 20px 0;
        height: 100px;

        &__bubbles {
            width: 12%;
            height: 50px;
            border: 1px;

            &:not(:first-child) {
                margin-left: 17px;
            }

            &--animate {
                transition: width .6s ease-in-out, margin .6s ease-in-out;
            }

            img {
                width: 100%;
                @include border-radius(50%);
                background-color: #FFF;
            }

            &:nth-child(1), &:nth-child(6) {
                width: 10%;
            }

            &:nth-child(2), &:nth-child(5) {
                width: 11%;
            }

            &:nth-child(1) {
                align-self: flex-start;
                order: 1;
            }

            &:nth-child(2) {
                align-self: flex-end;
                order: 2;
            }

            &:nth-child(3) {
                align-self: flex-start;
                order: 3;
                transition: all .6s ease-in-out;
            }

            &:nth-child(4) {
                align-self: flex-start;
                order: 5;
            }

            &:nth-child(5) {
                align-self: flex-end;
                order: 6;
            }

            &:nth-child(6) {
                align-self: flex-start;
                order: 7;
            }

            &:nth-child(7) {
                align-self: flex-end;
                order: 4;
                width: 12%;
            }
        }
    }

    &--on-the-bottom &__bubbles-list {
        top: 400px;

        &__bubbles {
            &:nth-child(7) {
                width: 0;
                margin-left: 0;
            }
        }
    }
}
