@use 'sass:math';

@mixin loader($size: 40px, $speed: .5s) {
    border-radius: 100%;
    width: $size;
    height: $size;
    margin: 0 auto;
    position: relative;
    text-indent: -9999em;
    color: rgba(255, 255, 255, 0.2) !important;
    border: math.div($size, 10) solid;
    border-left-color: var(--fleurDeLis);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 $speed infinite linear;
    animation: load8 $speed infinite linear;
    overflow: hidden;

    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}
