.visibility-modal {
    width: 100%;

    @media (min-width: $screen-sm-min) {
        max-width: 414px;
    }

    &__title {
        @extend %montserrat;
        font-weight: bold;
        font-size: 26px;
    }

    p {
        @extend %open-sans;
    }

    &__explanation {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 29px;
    }

    &__checkbox {
        margin-bottom: 58px;
    }

    &__warning {
        font-size: 13.5px;
        line-height: 19px;
        margin-top: 20px;
    }

    .help-tooltip-btn + .popover {
        left: -100px;
    }
}
