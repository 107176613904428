@mixin size-h1() {
    font-size: 30px;
    line-height: 37px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
}

@mixin size-h2() {
    font-size: 26px;
    line-height: 32px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
}

@mixin size-h3() {
    font-size: 22px;
    line-height: 27px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
}

@mixin size-h4() {
    font-size: 18px;
    line-height: 22px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
}

@mixin size-h5() {
    font-size: 16px;
    line-height: 19px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
}

@mixin size-h6() {
    font-size: 14px;
    line-height: 18px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
}
