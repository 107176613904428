.unsubscribe-content {
    padding: 0 25px;
    text-align: left;

    &__logo {
        max-width: 210px;
        height: 24px;

        img {
            object-fit: fill;
        }
    }

    &__main {
        overflow-y: hidden;

        &--animate {
            position: absolute;
            padding-right: 25px;

            .header {
                margin-top: 10px;
            }

            .header-no-email {
                padding-top: 25px;
            }

            .mp-main-button.bt-md.outline-fleur-de-lis {
                width: 100%;
            }

            @media (min-width: $screen-sm-min) {
                .mp-main-button.bt-md.outline-fleur-de-lis {
                    width: 462px;
                }
            }
        }
    }

    &__mp-links {
        padding-top: 30px;

        a {
            color: $fleur-de-lis;
            text-decoration: underline;
            font-weight: 700;
        }
    }

    &--email {
        padding-top: 26px;

        .email-obfuscated {
            font-size: 16px;
            font-weight: 700;
        }
    }

    &--actions-title {
        padding-top: 10px;
        font-weight: 700;
    }

    &--action-buttons {
        .action-item {
            padding: 15px 20px 15px 0;
            border-bottom: 1px solid #DEDEDE;
            background: transparent url('../img/right-chevron.svg') right center no-repeat;
            background-size: 14px;

            a {
                color: $mp-main-dark;
                font-size: 18px;
                display: block;
            }
        }
    }

    @media (min-width: $screen-md-min) {
        padding: 0 38px;
    }
}
