.boost-onboarding {
    overflow: hidden;

    &__scroller{
        height: 420px;
        width: 580px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden !important;

        &::-webkit-scrollbar {
            width: 0;
        }

        @media (max-width: $screen-xbs-max) {
            width: 100vw;
        }
    }

    &__footer {
        height: 56px;
        width: 580px;
        position: relative;
        margin-left: auto;
        margin-right: auto;

        &__button {
            cursor: pointer;
            position: absolute;
            color: #4400BB;
            font-weight: bold;

            i.material-icons {
                font-size: 30px;
                margin: 13px;
            }

            &--finish {
                height: 36px;
                width: 36px;
                color: #FFF;
                background: #4400BB;
                right: 10px;
                bottom: 10px;
                @include border-radius(4px);

                i.material-icons {
                    font-size: 30px;
                    margin: 3px;
                }
            }

            &--inactive {
                opacity: 0.5;
            }

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }

        &__markers {
            height: 100%;
            width: 60px;
            margin-left: calc(50% - 30px);
            display: flex;
            padding: 28px 0;

            li.marker {
                cursor: pointer;
                height: 8px;
                width: 8px;
                background: #DDDDDD;
                margin-right: 5px;
                @include border-radius(50%);

                &__active {
                    cursor: default;
                    background: #4400BB;
                    width: 16px;
                    @include border-radius(4px);
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        @media (max-width: $screen-sm-min) {
            width: 100vw;
        }
    }

    &__section {
        padding-left: 15px;
        padding-right: 15px;
        width: 580px;

        @media (min-width: $screen-sm-min) {
            padding-left: 25px;
            padding-left: 25px;
        }

        @media (max-width: 580px) {
            width: 100vw;
        }
    }

    &__title {
        @include size-h3();
    }

    &__subtitle {
        @include size-h4();
    }

    &__description {
        margin-top: 10px;
        color: #666;
    }

    &__icons {
        position: relative;
        height: 290px;
        width: 450px;
        margin-left: calc(50% - 225px);

        &__boosted-icon {
            position: absolute;
            height: 62px;
            @include border-radius(50%);
            bottom: 15px;
            left: 192px;
            z-index: 1;

            @media (max-width: $screen-xbs-max) {
                height: 48px;
                left: 125px;
                bottom: 5px;
            }
        }

        @media (max-width: $screen-xbs-max) {
            height: 210px;
            width: 300px;
            margin-left: calc(50% - 150px);
        }
    }

    &__cloud {
        position: absolute;
        background-image: url('../img/cloud.svg');
        height: 35px;
        z-index: -1;
        top: 315px;
        width: 100%;

        @media (max-width: $screen-xbs-max) {
            top: 210px;
        }
    }

    &__white-div {
        background: white;
        height: calc(100% - 350px);
        width: 100%;
        z-index: -1;
        position: absolute;
        bottom: 0px;

        @media (max-width: $screen-xbs-max) {
            height: calc(100% - 240px);
        }
    }
}

.boost-onboarding-boosted {
    position: absolute;
    top: 70px;
    left: calc(50% - 97px);
    width: 194px;
    @include border-radius(50%);
    border: 10px solid #4208BD;

    @media (max-width: $screen-xbs-max) {
        top: 40px;
        left: calc(50% - 77px);
        width: 154px;
    }

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 4px solid #FFF;
        @include border-radius(50%);
    }
}

.boost-onboarding-boosted--shadow1 {
    position: absolute;
    top: 80px;
    left: calc(50% - 97px);
    width: 194px;
    @include border-radius(50%);
    background: #4208BD;
    opacity: 0.15;

    @media (max-width: $screen-xbs-max) {
        top: 50px;
        left: calc(50% - 77px);
        width: 154px;
    }

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
}

.boost-onboarding-boosted--shadow2 {
    position: absolute;
    top: 95px;
    left: calc(50% - 97px);
    width: 194px;
    @include border-radius(50%);
    background: #4208BD;
    opacity: 0.25;

    @media (max-width: $screen-xbs-max) {
        top: 60px;
        left: calc(50% - 77px);
        width: 154px;
    }

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
}
