#newSearchBtn {
    cursor: pointer;
}

#savedSearch {
    margin-bottom: 20px;

    .view-search-btn {
        cursor: pointer;
    }
}

.scroll-filter {
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 15px 0;
    position: relative;
}

@media (min-width: $screen-md-min) {
    .scroll-filter-col {
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overscroll-behavior: contain;
    }
}

.search-header {
    height: 40px;

    .row {
        margin: 0;
        background-color: $mp-main-dark;
        position: absolute;
        left: 0;
        height: 40px;
        width: 100%;
        line-height: 32px;
        color: #FFF;

        button {
            display: inline-block !important;
            color: #FFF;
            border: none;
            background: none;

            i {
                vertical-align: middle;
                margin-right: 10px;
            }

            span {
                line-height: inherit;
                vertical-align: middle;
            }
        }
    }
}

.scroll-filter-wrapper {
    @media (min-width: $screen-md-min) {
        position: fixed;
        top: 70px;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.search-container {
    padding-top: 40px;

    select {
        margin-top: 10px;
    }

    .search-dd-btn {
        text-align: left !important;
        width: 100%;

        span {
            right: 10px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .dropdown-menu {
        width: 100%;
        z-index: 9;

        .dropdown-btn-icon {
            position: relative;

            span {
                font-size: 16px;
                vertical-align: middle;
            }

            .indication-icon {
                margin-right: 6px;
            }

            &.saved-search-btn {
                .indication-icon {
                    display: none;
                }

                &.current {
                    .indication-icon {
                        display: inline-block;
                    }
                }
            }

            p {
                margin-bottom: 0;
                display: inline-block;
                vertical-align: middle;
            }

            .delete-icon {
                right: 0;
                position: absolute;
                font-size: 18px;
                top: 0;
                bottom: 0;
                padding: 0 15px;
                color: inherit;

                .material-icons {
                    font-size: inherit;
                }
            }
        }
    }

    .search-form {
        .title {
            font-size: 22px;
            margin-bottom: 13px;

            &.others {
                margin-top: 30px;
            }
        }

        label.inner-group {
            font-size: 18px;
            line-height: 24px;
            margin: 15px 0;
        }

        .custom-radio-check {
            margin-bottom: 9px;
        }
    }

    .form-keywords label {
        font-size: 14px;
        margin-top: 17px;
    }

    .form-group.form-distance {
        padding-top: 15px;
    }

    #search-accordion,
    #search-adv-accordion {
        margin-bottom: 0 !important;

        .search-group {
            .panel-title {
                font-size: 14px;
                font-weight: 700;

                button {
                    padding: 0;
                    display: block;
                    width: 100%;
                    height: 54px;
                    border: 0;
                    background: transparent;
                }
            }

            .panel-heading {
                padding: 0;
            }

            .panel-body {
                padding: 0 0 20px 5px;
            }

            .panel-heading button {
                position: relative;
                display: block;

                &:after {
                    font-family: Material Icons;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 24px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    transform: rotate(90deg);
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: antialiased;
                    transition: transform .25s linear;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    width: 24px;
                    height: 20px;
                    text-align: center;
                    line-height: 20px;
                    right: 0;
                }
            }

            .panel-heading button[aria-expanded="true"]:after {
                content: "\e5cc";
                transform: rotate(270deg);
            }

            .panel-heading button[aria-expanded="false"]:after {
                content: "\e5cc";
            }

            .panel-heading button#show-adv-list[aria-expanded="false"]:after {
                transform: rotate(0deg);
            }
        }
    }

    .panel-body {
        border-top: 0 !important;
        padding-left: 0 !important;
    }

    .btn-clear {
        font-size: 14px;
        color: inherit;
        text-decoration: underline;
        padding: 2px 0;
    }

    .loader-container {
        margin-top: 50px;
    }

    .search-result {
        padding: 20px 5px;
        min-height: 100vh;

        .search-result-details {
            margin: 20px 0;
            padding: 0 10px;
        }

        .title {
            font-size: 22px;
        }

        .search-save {
            margin: 15px 0;
        }

        @media (min-width: $screen-md-min) {
            .search-result-details {
                padding: 0;
            }

            .title {
                float: left;
            }

            .search-save {
                float: right;
                margin: 0;
                flex-direction: row-reverse;

                > :first-child {
                    margin-left: 10px;
                }
            }
        }
    }

    .form-submit-container {
        padding-top: 20px;
        padding-bottom: 10px;
    }
}

.search-group {
    background-color: #FFF;
    transition: background-color 2s .5s;
    margin-left: -20px;
    padding-left: 20px;
    margin-right: -20px;
    padding-right: 20px;

    &.form-group {
        margin-top: 0;
        padding-top: 1px;
        margin-bottom: 0;
        padding-bottom: 15px;
    }

    &.highlight {
        background-color: #FE9;
        transition: none;
    }
}

.span-normal {
    font-weight: 400;
}

.ng2-nouislider {
    .noUi-target {
        height: 6px;

        .noUi-base {
            .noUi-connect {
                background: linear-gradient(to right, #674EF8, #F74041);
            }

            .noUi-origin {
                .noUi-handle {
                    border-radius: 100%;
                    width: 20px;
                    height: 20px;
                    top: -8px;
                    right: -10px;
                    box-shadow: none;
                    border: none;
                    background: var(--galaxyBlue);

                    &:before,
                    &:after {
                        content: none;
                    }

                    .noUi-tooltip {
                        display: none;
                        color: #FFF;
                        background-color: $mp-main-dark;
                        font-size: 0.9em;
                    }

                    &:hover,
                    &:focus {
                        .noUi-tooltip {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }
}

.baby-premium-banner {
    color: $fleur-de-lis;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    margin-bottom: -10px;

    .baby-premium-badge {
        width: 16px;
        height: 16px;
        vertical-align: -2px;
        margin-right: 2px;
    }
}

.cell-title {
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 30px);
    font-size: 14px;

    > * {
        width: calc(50% - 5px);
    }

    > div:first-child {
        font-size: 18px;
        text-align: left;
    }

    label {
        margin: 0;
    }

    .current-value {
        font-weight: normal;
        text-align: right;
    }

    &.no-drop {
        width: 100%;
    }
}

.search-save {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-md-min) {
        margin-top: 20px;
    }
}

.active-filters {
    display: flex;
    overflow: auto;
    white-space: nowrap;
    margin: 15px -15px;
    padding: 0 15px;

    @media (min-width: $screen-md-min) {
        white-space: initial;
        flex-wrap: wrap;
    }

    li {
        background: #FFF;
        border: 1px solid #DDD;
        border-radius: 4px;
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 15px;
        line-height: 18px;
        display: flex;

        .filter {
            margin: 0;
            padding: 8px 15px;
            border: 0;
            background: transparent;
            font: inherit;
        }

        .close {
            margin: 0 0 0 -15px;
            padding: 0 10px;
            border: 0;
            background: transparent;
            color: #666;

            .material-icons {
                font-size: 18px;
                display: block;
                line-height: inherit;
            }
        }
    }
}
