:root {
       /* User Themes */
    .prince-theme {
        border: solid 1px var(--galaxyBlue);
        color: var(--galaxyBlue);
        background-color: var(--ui1);

        &--active {
            color: var(--ui1);
            background-color: var(--galaxyBlue);
        }

        &--active-white {
            border: solid 1px var(--galaxyBlue);
            color: var(--galaxyBlue);
            background-color: var(--ui1);

            &:focus,
            &:active,
            &--active {
                color: var(--galaxyBlue);
            }
        }

        &--active-minsk {
            border: solid 1px var(--galaxyBlue);
            color: var(--ui1);
            background-color: var(--galaxyBlue);

            &:focus,
            &:active,
            &--active {
                color: var(--ui1);
                background-color: var(--minsk);
            }
        }
    }

    .cinderella-theme {
        border: solid 1px var(--radRed);
        color: var(--radRed);

        &:focus,
        &:active,
        &--active {
            color: var(--ui1);
            background-color: var(--radRed);
        }
    }

    /* Color Themes */
    .fleur-de-lis {
        background-color: var(--fleurDeLis);
        color: var(--ui1);

        &--inverted {
            background-color: var(--ui1);
            color: var(--fleurDeLis);

            svg circle {
                stroke: var(--fleurDeLis);
            }
        }
    }

    .rad-red {
        background-color: var(--radRed);
        color: var(--ui1);

        &--inverted {
            background-color: var(--ui1);
            color: var(--radRed);

            svg circle {
                stroke: var(--radRed);
            }
        }
    }

    .text-fleur-de-lis {
        color: var(--fleurDeLis);
    }
}
