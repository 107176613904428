@use 'sass:math';

$bubbleIncreaseSize: 0.575em;
$bubbleBaseSize: 0.75em;

@mixin generateBubbles($bubbles, $profileBubbles, $boostIcon) {
    $bubblesHalf: math.div($bubbles, 2);

    #{$profileBubbles} {
        transform: translatey(0);
        animation: float 6s ease-in-out infinite;

        @for $i from 1 through $bubbles {
            &:nth-child(#{$i}) {
                order: bubbleOrder($i, $bubblesHalf);
                width: bubbleSize($i, $bubblesHalf);
                height: bubbleSize($i, $bubblesHalf);
                border-radius: bubbleSize($i, $bubblesHalf);
                align-self: arrangePosition($i, $bubblesHalf);
            }

            &:nth-child(2n + 2) {
                margin-right: -0.125em;
            }
        }
    }

    #{$boostIcon}{
        order: $bubblesHalf + 1;
        border-radius: bubbleSize($bubblesHalf + 1, $bubblesHalf);
        width: bubbleSize($bubblesHalf + 1, $bubblesHalf);
        height: bubbleSize($bubblesHalf + 1, $bubblesHalf);
        margin-top: 17px;
        @include boxShadow(0, 0.313em, 0.625em, 0, 0, 0, 0, 0.2);
    }
}

@function bubbleOrder($bubbleIndex, $bubblesHalf) {
    @if $bubbleIndex >= ($bubblesHalf + 1) {
        @return $bubbleIndex + 1;
    }

    @return $bubbleIndex;
}

@function arrangeBubblesOrder($bubbleIndex, $bubblesHalf) {
    @if $bubbleIndex <= $bubblesHalf {
        @return $bubbleIndex;
    }

    @return -($bubbleIndex - $bubblesHalf) + $bubblesHalf + 1;
}

@function bubbleSize($bubbleIndex, $bubblesHalf) {
    @return $bubbleBaseSize + ($bubbleIncreaseSize * arrangeBubblesOrder($bubbleIndex, $bubblesHalf));
}

@function arrangePosition($bubbleIndex, $bubblesHalf) {
    @if arrangeBubblesOrder($bubbleIndex, $bubblesHalf) == 2 {
        @return flex-end;
    }

    @return flex-start;
}

.boost-nagbar {
    background-color: #FFF;
    border: 0.4em solid #FFF;
    @include boxShadow(0, 0, 0.188em, 0, 0, 0, 0, 0.16);
    margin-bottom: 1.25em;
    font-size: 16px;

    @media (max-width: $screen-xbs-max) {
        font-size: 14px;
    }

    &__header {
        display: flex;
        justify-content: space-evenly;
        background: url('../img/boost-cloud-bg.svg') repeat-x;
        padding-top: 1.875em;
    }

    &__body {
        padding: 0 2.1em;
        text-align: center;
        background-color: #FFF;
        padding-bottom: 1.875em;

        .mp-main-button {
            width: 240px;
            font-size: 1em;

            @media (max-width: $screen-xbs-max) {
                width: 100%;
            }

            @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                width: 100%;
            }
        }
    }

    &__title {
        font-size: 1.375em;
        margin: 0;
        margin-top: 15px;

        span {
            font-size: 1.2em;
        }
    }

    &__subtitle {
        font-size: 1em;
        margin-bottom: 1.5em;
        margin-top: 0.3em;
    }

    @include generateBubbles(6, "&__profile-photo", "&__boost-icon");
}

@keyframes float {
	0% {
		transform: translatey(0);
	}
	50% {
		transform: translatey(-0.438em);
	}
	100% {
		transform: translatey(0);
	}
}
