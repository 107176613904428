.slidable-menu-panel {
    overflow-y: scroll;
    overflow-x: hidden;
}
.slidable-menu-backdrop {
    background-color: rgba(0, 0, 0, .7);
}
.slidable-menu-bar {
    width: 290px;
    white-space: nowrap;
    background: #FFF;
    min-height: 100%;
    padding: 25px 15px 0;

    &__user-header {
        display: flex;
        height: 40px;
        justify-content: space-between;
    }

    &__user-buttons {
        margin: 30px 0 20px 0;
    }

    &__subscribed-status {
        margin: 11px 0;
    }

    &__menu-separator {
        border-top: 1px solid #DDD;
        margin-left: -15px;
        margin-right: -15px;
    }

    &__actions {
        margin-top: 20px;
    }
}
