.boost-onboarding-views {
    .boost-onboarding__icons {
        &__boosted-icon {
            @include boxShadow(0, 0, 10px, 0, 0, 0, 0, 0.31);

            &--position0,
            &--position2 {
                height: 40px;
                width: 40px;
                top: 187px;

                @media (max-width: $screen-xbs-max) {
                    height: 32px;
                    width: 32px;
                    top: 128px;
                }
            }

            &--position0 {
                left: 63px;

                @media (max-width: $screen-xbs-max) {
                    left: 27px;
                }
            }

            &--position1 {
                height: 55px;
                width: 55px;
                left: 197px;
                top: 210px;

                @media (max-width: $screen-xbs-max) {
                    height: 50px;
                    width: 50px;
                    top: 140px;
                    left: 125px;
                }
            }

            &--position2 {
                right: 63px;
                left: auto;

                @media (max-width: $screen-xbs-max) {
                    right: 27px;
                }
            }
        }

        .boost-onboarding-boosted {
            top: 50px;

            @media (max-width: $screen-xbs-max) {
                top: 20px;
            }

            &__profile-pic {
                &--position0,
                &--position2 {
                    width: 130px;
                    top: 80px;
                    border: 6px solid #4208BD;

                    @media (max-width: $screen-xbs-max) {
                        width: 90px;
                        top: 60px;
                    }
                }

                &--position0 {
                    left: 20px;

                    @media (max-width: $screen-xbs-max) {
                        left: 0px;
                    }
                }

                &--position1 {
                    z-index: 1;

                    .boost-onboarding-boosted__image {
                        border: 6px solid #FFF;
                    }
                }

                &--position2 {
                    left: auto;
                    right: 20px;

                    @media (max-width: $screen-xbs-max) {
                        right: 0px;
                    }
                }
            }
        }
    }
}
