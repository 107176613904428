body.in-boost {
    padding-bottom: 0;
}

.boost-container {
    @extend %boost-container-common;

    &__cloud-background {
        @extend %cloud-background-common;

        &--full-background {
            @extend %cloud-background--full-background;
        }

        &--full-width {
            @extend %cloud-background--full-width;
        }
    }

    &__content {
        position: absolute;
        width: 100%;
        height: auto;
        transition: top .6s ease-in-out;
        top: -800px;

        &--initial-state {
            width: 100%;
            height: 500px;
        }

        &--activatable {
            width: 100%;
            height: 400px;
        }

        &--activated {
            width: 100%;
            height: 400px;
        }
    }

    &__loading {
        .lds-css {
            position: absolute;
            left: 50%;
            margin-left: -30px;
            top: 50%;
            margin-top: -30px;
        }
    }

    mp-boost-profile-bubbles {
        display: block;
        transition: opacity .6s ease-in-out;
    }

    mp-boost-generic-content {
        display: block;
        opacity: 1;
        transition: opacity .6s ease-in-out;
    }

    mp-boost-activatable, mp-boost-activated {
        display: block;
        opacity: 0;
        transition: opacity .6s ease-in-out;
        transition-delay: .3s;
        pointer-events: none;
    }

    &--state-activated {
        mp-boost-activated {
            opacity: 1;
            pointer-events: all;
        }

        mp-boost-profile-bubbles {
            display: block;
            opacity: 0;
            pointer-events: none;
        }
    }

    &--state-activatable {
        mp-boost-generic-content {
            opacity: 0;
            pointer-events: none;
        }

        mp-boost-activatable {
            opacity: 1;
            pointer-events: all;
        }
    }

    &--state-activatable &__cloud-background {
        top: 400px;
    }

    &--state-activatable &__content {
        top: -400px;
    }

    &--state-activated &__cloud-background {
        top: 450px;
    }

    &--state-activated &__content {
        top: 0;
    }
}
