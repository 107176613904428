.activate-boost-button {
    .lds-css {
        position: unset;
        left: unset;
        margin-left: unset;
        top: unset;
        margin-top: unset;
        width: 20px;
        height: 20px;
        border-left-color: #FFF !important;
        border: 3px solid;
        margin-left: 10px;
    }

    .mp-main-button.bt-md {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    i {
        margin-left: 5px;
    }
}
