.boost-generic-content {
    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        text-align: center;
        color: $mp-main-dark;
        padding: 2px 20px 0;
        max-width: 500px;
        margin: auto;

        &__title {
            font-family: 'Montserrat';
            font-size: 26px;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: 0.3px;
            margin-bottom: 5px;
        }

        &__subtitle {
            font-size: 16px;
            line-height: 1.4;
            letter-spacing: 0.2px;
        }

        &__buttons-block {
            width: 100%;
        }

        &__projection {
            &:not(:empty) {
                margin-top: 38px;
                width: 100%;
            }
        }

        &__notnow {
            display: inline-block;
            background: transparent;
            color: inherit;
            border: 0;
            margin: 0;
            padding: 0;
            text-decoration: underline;
        }

        &__footer {
            &--title {
                font-weight: 800;
                font-size: 16px;
                margin-bottom: 5px;
            }
        }

        .mp-main-button.bt-md {
            margin-top: 40px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .boost-icon {
            margin-left: 5px;
        }
    }
}
