.registration-confirm-email {
    &__instructions {
        p {
            text-align: start;
        }

        a {
            display: inline;
            text-decoration: underline;
            color: $fleur-de-lis;
        }
    }

    h1 {
        margin-bottom: 28px;
    }

    h1, p {
        text-align: center;
    }

    &__buttons-container {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__button-logout {
            background: none;
            display: block;
            font-size: 16px;
            line-height: 1.5;
            text-decoration: underline;
            text-align: center;
            color: inherit;
            border: 0;
            margin: 0 auto;
            padding: 0;
            margin-top: 30px;
        }
    }
}
