.registration-logo {
    height: 18px;

    &--desktop {
        height: 24px;
        margin-bottom: 44px;

        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }
}

.registration-header {
    height: 50%;
    width: 100%;
    background-color: $mp-main-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;

    @media (min-width: $screen-md-min) {
        display: none;
    }

    &__logo {
        height: 18px;
    }
}

.registration-canvas {
    display: flex;
    flex-direction: column;

    &--modal-opened {
        overflow: hidden;
    }

    @media (min-width: $screen-md-min) {
        flex-direction: row;
        width: 100%;
        height: 100vh;

        &__background,
        &__container {
            flex: 0 0 50%;
        }

        &__background {
            overflow: hidden;
            position: fixed;
            order: 2;
            max-width: 50%;
            top: 0;
            bottom: 0;
            right: 0;

            .registration-background-image {
                height: 100%;
                width: 100%;
            }
        }

        &__container {
            order: 1;
            padding-top: 58px;
            display: flex;
            justify-content: flex-end;
        }
    }

    @media (max-width: $screen-sm-max) {
        &__background {
            display: none;
        }
    }

    &__container {
        .registration-title {
            font-size: 30px;
            margin-top: 0;
        }

        .registration-subtitle {
            margin: 0 0 10px;
        }

        .registration-box {
            border-radius: 12px;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
            background-color: #FFF;
            margin-top: 30px;
            padding: 30px 66px;

            &__title {
                text-align: center;
                font-size: 26px;
                margin: 0 0 25px;
            }

            .sugar-profiles-explanation {
                display: block;
                color: inherit;

                &__link {
                    color: $fleur-de-lis;
                    text-decoration: underline;
                }
            }
        }

        .registration-terms-resume {
            display: block;
            font-size: 13px;
            margin-top: 20px;

            &__link {
                color: #000;
                text-decoration: underline;
                font-weight: bold;
            }
        }

        @media (min-width: $screen-xl-min) {
            padding-right: 136px;

            .registration-form-items {
                width: 574px;

                .registration-box {
                    padding: 30px 75px;
                }
            }
        }

        @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
            padding-right: 112px;

            .registration-form-items {
                width: 458px;

                .registration-box {
                    padding: 30px 65px;
                }
            }
        }

        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            padding-right: 96px;

            .registration-form-items {
                width: 374px;

                .registration-box {
                    padding: 30px 58px;
                }
            }
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            margin: 30px 24px;
        }

        @media (max-width: $screen-xs-max) {
            margin: 30px 15px;
        }

        @media (max-width: $screen-sm-max) {
            .registration-title {
                font-size: 22px;
            }

            .registration-box {
                padding: 20px 15px;

                &__title {
                    font-size: 22px;
                }
            }
        }
    }

    &__login-button {
        padding-top: 20px;
        padding-bottom: 48px;

        div {
            font-size: 14px;
        }

        a {
            color: $fleur-de-lis;
            font-size: 15px;
            font-weight: 700;
            text-decoration: underline;
        }
    }

    &__business-info {
        margin: 50px 0;
    }
}
