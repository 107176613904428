.registration-image-upload {
    display: flex;
    margin: 0 -5px;

    &__label {
        display: block;
        flex: 1;
        margin: 0 5px;
    }

    &__container {
        @include aspect-ratio(1px, 1px);
        background-color: #DDD;
        @include border-radius(6px);
        margin-bottom: 30px;
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        position: relative;
        overflow: hidden;

        @media (min-width: $screen-sm-min) {
            max-width: 320px;
            margin: 0 auto 30px;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
            width: 25px;
            height: auto;
            margin-bottom: 8px;
        }

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__file-input {
            position: absolute;
            left: -500%;
            top: 0;
            width: 600%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.registration-image-upload-main-image {
    height: 40px;
    width: 40px;
    background-color: transparent;
    display: flex;
    align-items: center;
    position: relative;

    &--empty {
        background: #DDD url('../img/camera.svg') center center no-repeat;
        background-size: 18px auto;
        border-radius: 50%;
    }

    &__image {
        width: 100%;
        border-radius: 50%;
    }

    &__label {
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    &__file-input {
        opacity: 0;
    }
}
