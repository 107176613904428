main.upgrade-page {
    .page-title {
        h1 {
            margin-bottom: 0;
        }

        h4 {
            margin: 6px 0 30px;
            line-height: 24px;
        }
    }

    &.baby-premium {
        background: url('../img/red-circles-illustration.svg') right -610px top 250px no-repeat;

        @media (min-width: $screen-sm-min) {
            background-position: right -450px top -540px;
        }

        .plan-box h1 {
            padding-bottom: 20px;
        }
    }

    .main-button {
        border-radius: 4px;
        background-color: $fleur-de-lis;
        display: inline-block;
        text-align: center;
        padding: 15px 8px;
        color: #FFF;
        text-decoration: none;
        font-weight: 700;
        font-size: 18px;
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .2);

        &:hover {
            background-color: lighten($fleur-de-lis, 3%);
        }

        &:active {
            background-color: darken($fleur-de-lis, 3%);
        }
    }

    .plan-box {
        border-radius: 10px;
        background-color: #FFF;
        padding: 4px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, .25);
        margin-bottom: 15px;

        .featuring-list {
            margin: 20px 11px;

            li {
                padding: 6px 0;
                border-bottom: 1px solid #DDD;
                position: relative;
                width: 100%;
                display: flex;
                align-items: flex-start;

                img {
                    width: 24px;
                    margin-right: 6px;
                }

                &:last-child {
                    border: none;
                    padding-bottom: 0;
                }
            }

            @media (min-width: $screen-sm-min) {
                margin: 30px 0 0;
            }
        }

        &.black {
            background-color: #111;
            color: #FFF;

            .plan-selector {
                background-color: #2D2D2D;

                .item {
                    color: #F3F3F3;
                    &:not(.active) {
                        &:hover {
                            background-color: rgba(238, 238, 238, .06);
                        }
                    }
                }
            }

            .price-container,
            .payment-container {
                background: #202020;
            }

            .featuring-list {
                li {
                    border-color: #444;
                }
            }
        }

        h1 {
            margin: 20px 0 0;
            font-size: 24px;
            text-align: center;

            @media (min-width: $screen-md-min) {
                margin: 0;
                font-size: 26px;
            }
        }

        > p {
            text-align: center;
            margin: 4px 11px 0;
            padding-bottom: 10px;

            @media (min-width: $screen-md-min) {
                padding-bottom: 25px;
            }
        }

        .payment-container {
            background: #F6F6F6;
            text-align: center;
            border-radius: 0 0 10px 10px;
            font-size: 22px;
            line-height: 22px;
            padding-bottom: 30px;

            .main-button {
                display: block;
                width: 260px;
                margin: 30px auto 0;
            }
        }

        .featuring-sbp {
            margin: 20px 11px 30px;
        }

        @media (min-width: $screen-md-min) {
            padding: 30px;
        }
    }

    .plan-selector {
        background-color: #E9E9E9;
        border-radius: 10px 10px 0 0;
        min-height: 60px;
        display: flex;
        position: relative;
        margin: 24px 0 0;

        li {
            flex: 1;
            position: relative;
        }

        .item {
            height: 60px;
            width: 100%;
            border-radius: 10px;
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-size: 18px;
            line-height: 18px;
            font-weight: 700;
            transition: background-color 200ms, color 200ms;
            padding: 0;

            span {
                font-size: 16px;
                font-weight: 400;
            }

            &:not(.active) {
                &:hover {
                    background-color: rgba(238, 238, 238, .8);
                }
            }

            &.active {
                background-color: #555555;
                color: #F3F3F3;
            }

            @media (min-width: $screen-sm-min) {
                font-size: 20px;
                line-height: 22px;

                span {
                    font-size: 18px;
                }
            }
        }

        &.baby-premium {
            background: url('../img/red-circles-illustration.svg') right -610px top 250px no-repeat;

            @media (min-width: $screen-sm-min) {
                background-position: right -450px top -540px;
            }

            .plan-box h1 {
                padding-bottom: 20px;
                font-size: 24px;

                @media (min-width: $screen-md-min) {
                    font-size: 26px;
                }
            }
        }
    }

    .price-container {
        background: #F6F6F6;
        position: relative;
        text-align: center;

        p, div {
            font-size: 30px;
            display: inline-block;
            vertical-align: middle;
        }

        .price {
            font-size: 70px;
        }

        @media(min-width: 375px) {
            .price {
                font-size: 110px;
            }
        }
    }

    .popular-tip {
        background-color: $fleur-de-lis;
        font-size: 13px;
        white-space: nowrap;
        text-align: center;
        border-radius: 12px 12px 0 0;
        height: 24px;
        line-height: 24px;
        color: #FFF;
        font-weight: 400;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 12px;

        @media (min-width: $screen-md-min) {
            padding: 0 17px;
        }
    }

    .payment-methods-container {
        margin: 0 0 30px;

        ul.cards-flag {
            li {
                margin: 0 5px 5px 0;
                display: inline-block;
                vertical-align: top;

                img {
                    border: none;
                    width: auto;
                    height: 32px;
                }
            }
        }
    }

    .mini-terms {
        margin-top: 20px;

        @media(min-width: $screen-sm-min) {
            margin-top: 60px;
        }
    }
}

.promo {
    padding: 30px 15px;
    max-width: 600px;
    margin: auto;
}

.big-title {
    font-size: 20px;
    text-align: center;
}

.big-title button {
    color: $fleur-de-lis;
    text-decoration: underline;
    margin-left: 4px;
    background: transparent;
    border: 0;
    cursor: pointer;
}

.promo-form {
    padding: 20px 0;
}

.promo-form > div {
    position: relative;
    margin: auto;
    width: 100%;
}

.promo-form > div > div {
    font-size: 12px;
    margin-top: 4px;
}

.promo-form input {
    border: 1px solid #BBB;
    border-radius: 4px;
    font-size: 16px;
    height: 50px;
    padding: 0 40px 0 14px;
    width: 100%;
}

.promo-form input[type='text'] {
    color: $mp-main-dark;
}

.promo-form button {
    background: transparent;
    border: none;
    text-decoration: underline;
    position: absolute;
    right: 5px;
    height: 50px;
}

.promo-form button > .spin-loader {
    display: none;
}

.promo-form button.loading > .spin-loader {
    display: block;
    padding-top: 3px;
}
.promo-form button.loading > div {
    display: none;
}

.promo-form {
    border: none;
    outline: none;
    background-color: transparent;
    color: $fleur-de-lis;
    right: 10px;
    top: 0;
}

.payment-sucess {
    padding: 30px 15px;
    text-align: center;
}

@media (min-width: 768px) {
    .promo-form > div {
        max-width: 320px;
    }
}

.title-row {
    @media (min-width: $screen-sm-min) {
        display: flex;
        align-items: center;
    }
}

.featuring-sbp {
    dt {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 6px;

        @media (min-width: $screen-sm-min) {
            font-size: 22px;
            line-height: 27px;
            margin: 0 0 2px;
        }
    }

    dd {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 22px;

        @media (min-width: $screen-sm-min) {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;
        }
    }
}
