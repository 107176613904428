.user-avatar {
    display: flex;
    flex-flow: row nowrap;
    @extend %btn-no-styles;

    &__img {
        @include square(40px);
        @include border-radius(20px);
    }

    &__user-texts {
        text-align: left;
        margin-left: 6px;
    }

    &--username {
        font-weight: 700;
        font-size: 16px;
    }

    &--see-my-profile {
        font-size: 14px;
    }
}
