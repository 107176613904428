.current-plan-warning {
    max-width: 460px;
    text-align: center;
    background-color: #FFF;
    @include border-radius(10px);
    margin: 20px auto;
    padding: 14px;
    @include boxShadow(0, 0, 5px, 0, 0, 0, 0, .1);
    @media (min-width: $screen-sm-min) {
        margin-top: 0;
    }
}

.plans-list {
    @media (min-width: $screen-sm-min) {
        margin-top: 30px;
    }
}

.plan-col {
    padding: 0;

    .content {
        padding: 30px 15px;

        h1 {
            margin: 0;
        }

         @media (min-width: $screen-sm-min) {
            padding: 30px;
        }

        .flex-select {
            margin-bottom: 20px;
        }

        img {
            max-width: 30px;
            max-height: 30px;
            margin-left: 10px;
        }
    }

    &.premium-container .content {
        background-color: #FFF;
    }

    &.elite-container {
        .content {
            background-color: $mp-main-dark;
            color: #CCC;
        }
        ul {
            background-color: #EAEAEA;
        }
    }

    ul {
        padding: 0 15px;

        li {
            display: flex;
            align-items: flex-start;

            border-bottom: 1px solid #CCC;
            padding: 12px 0;

            &:last-child {
                border-bottom: none;
            }

            p {
                margin: 0 0 0 10px;
            }

            i {
                color: #BBB;
            }
        }
    }
}

.flex-select {
    width: 100%;
    background-color: #F6F6F6;
    height: 44px;
    @include border-radius(22px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
        width:100%;
        @include border-radius(22px);
        color: #777;
        height: 44px;
        line-height: 1;
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 15px;

        span {
            font-size: 12px;
        }

        &:hover {
            background-color: #EEE;
        }

        &.active {
            background-color: #555;
            color: #FFF;
        }
    }

    &.dark-bg {
        background-color: #3D3D3D;

        .item {
            color: #CCC;

            &:hover {
                background-color: #444;
            }

            &.active {
                color: #FFF;
            }
        }
    }
}

.flex-button {
    background-color: $fleur-de-lis;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include border-radius(6px);
    padding: 0 15px;
    color: #FFF;

    &:hover {
        background-color: darken($fleur-de-lis, 5%);
        color: #FFF;
        text-decoration: none;
    }

    p {
        margin: 0;
        font-size: 24px;

        &:first-child {
            font-size: 18px;
            font-weight: 400;
        }
    }

    &.gold {
        background-color: $mp-gold;
        color: $mp-main-dark;
        &:hover {
            background-color: darken($mp-gold, 5%);
        }
    }
}
