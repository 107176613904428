.mp-safe-tip-nagbar {
    border-radius: 12px;
    color: #000;
    background: #FFF;
    padding: 20px;
    text-align: left;
    margin-bottom: 20px;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.05);
    position: relative;

    &__title {
        @include size-h3();
        padding-top: 3px;
    }

    &__description {
        margin-top: 9px;
        margin-bottom: 25px;
    }

    &__close-button {
        background: transparent;
        border: 0;
        color: #666;
        line-height: 1;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .material-icons {
            font-size: 19px;
        }
    }

    &--close-text {
        font-size: 15px;
        font-weight: normal;
    }
}
