@import 'loader.scss';

.incomplete-modal {
    &-box {
        width: 100%;
        min-height: 100px;
        background-color: #FFF;
        margin: auto;
        position: relative;

        &.full-img-bg {
            color: #FFF;
            padding: 50px 15px;
            background: #000 url('../img/modal-img.jpg') center center no-repeat;
            background-size: cover;
            position: relative;

            @media (min-width: 768px) {
                @include border-radius(4px);
            }
        }

        p.title {
            font-weight: 700;
            margin: auto;
            font-size: 28px;
            line-height: 1.2em;
        }

        .description {
            margin: 20px 0;
        }

        span {
            font-size: 18px;
            font-weight: inherit;
        }

        .actions {
            > * {
                margin: 8px 0;

                @media (min-width: 768px) {
                    margin: 8px 8px 8px 0;
                }
            }
        }
    }

    &-main-header {
        padding: 15px;
        position: relative;

        &.absolute {
            position: absolute;
            height: 50px;
            padding: 0;
            top: 0;
            left: 0;
            right: 0;
            margin-top: env(safe-area-inset-top);
            margin-left: env(safe-area-inset-left);
            margin-right: env(safe-area-inset-right);

            @media (min-width: 768px) {
                margin: 0;
            }
        }

        button {
            background-color: transparent;
        }

        .modal-close {
            width: 50px;
            height: 50px;
            border: none;

            img {
                border: none;
            }
        }

        .right-btn {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }
}

.frictionless-small-loader {
    @include loader(40px, 4.5s);
}
