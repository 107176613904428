.boost-onboarding-cycle {
    &__icons {
        .boost-onboarding__clock {
            position: absolute;
            width: 70px;
            top: 150px;
            
            @media (max-width: $screen-xbs-max) {
                width: 50px;
                top: 90px;
            }

            &--left {
                left: 10px;
            }

            &--center {
                width: 180px;
                margin-left: calc(50% - 95px);
                top: 60px;
                
                @media (max-width: $screen-xbs-max) {
                    width: 140px;
                    margin-left: calc(50% - 70px);
                    top: 20px;
                }
            }

            &--right {
                right: 10px;
            }
        }
    }
}
