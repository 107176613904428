.msg-limit-modal {
    text-align: center;

    .complete-registration {
        padding-top: 50px;
    }

    &.dial-box {
        position: relative;
        max-width: 700px;
    }

    .close-button {
        position: absolute;
        right: 20px;
        top: 20px;
        background: transparent;
        border: 0;
        padding: 0;
        color: #999;
        font-size: 24px;
    }

    h2 {
        font-size: 22px;
        line-height: 27px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    p {
        font-size: 18px;
        line-height: 24px;
        margin: 15px auto 0;

        &.title {
            margin-top: 35px;
            margin-bottom: 10px;
            line-height: 22px;
        }

        @media (min-width: $screen-md-min) {
            width: 82.5%;
        }
    }

    .buttons-container {
        max-width: 320px;
        margin: 0 auto;
        padding-top: 0;

        > * {
            margin-top: 20px;
        }
    }
}
