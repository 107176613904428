.feedback-nagbar {
    border-radius: 12px;
    background: #FFF;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);

    h2 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin: 0 -5px 8px;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 18px;
    }

    a {
        display: block;
        max-width: 272px;
        background: $fleur-de-lis;
        color: #FFF;
        padding: 8px;
        font-weight: 700;
        border-radius: 4px;
        margin: 0 auto 10px;
        text-decoration: none;
    }

    button {
        display: inline-block;
        background: transparent;
        color: inherit;
        border: 0;
        margin: 0;
        padding: 0;
        text-decoration: underline;
    }
}
