.page-menu {
    position: fixed;
    left: 0;
    top: 50px;
    width: 100%;
    height: 40px;
    z-index: 9;
    background-color: #444;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .05);

    @media(min-width: $screen-md-min) {
        top: 70px;
        height: 50px;
    }

    &.static {
        position: static;
    }

    .nav-tabs {
        border-bottom: 0;
        padding: 0;

        li {
            height: 40px;
            font-size: 15px;

            button {
                color: #CCC;
                width: 100%;
                height: 100%;
                border-radius: 0;
                border: none;
                font-size: 14px;
                padding: 0 5px;
                background-color: transparent;

                &:hover {
                    border: none;
                }
            }

            @media(min-width: $screen-md-min) {
                width: auto;
                padding: 0 15px;
                height: 30px;
                margin: 9px 10px;

                button {
                    width: auto;
                }
            }
        }

        li:hover {
            color: inherit;

            button {
                background-color: transparent;
                border: none;
                color: #FFF;
            }
        }

        .refresh-icon {
            display: none;
        }

        li.active {
            background-color: $fleur-de-lis;
            font-weight: 700;

            span {
                font-weight: 700;
            }

            button {
                background-color: transparent;
                border: none;
                color: #FFF;
            }

            &.inline-refresh {
                button {
                    display: inline-block;
                    vertical-align: top;
                }

                .refresh-icon {
                    display: none;
                    padding: 0;
                    cursor: pointer;
                    i {
                        font-size: 20px;
                        line-height: inherit;
                    }
                    @media(min-width: $screen-md-min) {
                        display: inline-block;
                    }
                }
            }

            @media(min-width: $screen-md-min) {
                @include border-radius(15px);
            }
        }
    }
}

.page-menu.md-transparent {
    outside-payment & {
        position: static;
    }

    @media(min-width: $screen-md-min) {
        position: static;
        background-color: transparent;
        @include boxShadow(0, 0, 0, 0, 0, 0, 0, 0);
        margin-left: -15px;
        height: auto;
        margin-top: 0;
        padding-top: 0;

        li {
            a, button {
                color: $mp-main-dark;

                &:hover {
                    color: #999;
                }
            }

            &.active:hover a, &.active:hover button {
                color: #FFF;
            }
        }
    }
}
