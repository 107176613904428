a.subscribe-button {
    @extend %open-sans;
    font-weight: 700;
    background-color: $fleur-de-lis;
    color: #FFF;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &:hover {
        background-color: $purple-hover;
    }

    &:active {
        background-color: $purple-active;
    }

    &--medium {
        font-size: 16px;
        height: 33px;
        padding: 5px 13px 4px;
    }

    &--large {
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 700;
        width: 100%;
        height: 44px;
    }

    &.hidden-on-medium-down {
        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }

    .personal-options-container & {
        @media (max-width: $screen-sm-max) {
            margin-bottom: 16px;
        }

        @media (min-width: $screen-md-min) {
            width: 240px;
            margin-right: 6px;
        }
    }

    .subscribed-status & {
        margin-right: 10px;
    }

    .subscribed-status.extended & {
        margin-right: 2px;
        margin-left: 2px;
    }

    .my-subscription & {
        margin-top: 40px;
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);

        &--large {
            @media (min-width: $screen-sm-min) {
                width: 240px;
                margin-right: 6px;
            }
        }
    }
}
