.new-password.outside-body {
    background-color: #F1F1F1;

    .main-button {
        width: 100%;
    }

    .page-form {
        margin-top: 20px;
    }

    .main-button.outside-main-link {
        margin-top: 20px;
        padding: 20px 16px;
        width: 100%;
    }

    .change-pass-success {
        text-align: center;
        padding: 30px 18px;
        background-color: #FFF;
        @include border-radius(12px);
        @include boxShadow(0, 0, 5px, 1px, 0, 0, 0, .1);
        h1 {
            margin-top: 0;
        }
    }

    .outside-banner {
        position: fixed;
        height: 100%;
        width: 40%;
        left: 60%;
        top: 0;
        @media (min-width: $screen-md-min){
            background: #000 url('../img/new-pass-banner.jpg') center center no-repeat;
            background-size: cover;
        }

        @media (min-width: $screen-lg-min) {
            width: 50%;
            left: 50%;
        }
    }

    .new-password-xs-sm-center {
        @media(max-width: $screen-sm-max) {
            text-align: left;
        }
    }

    .red-link {
        color: $fleur-de-lis;
    }
}
