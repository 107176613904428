body.in-chat {
    padding-bottom: 0;
    -webkit-overflow-scrolling: touch;
}

.chat-header {
    @media (min-width: $screen-md-min) {
        position: relative;
        background-color: #333;
        border-radius: 4px;
        padding: 15px;
        height: auto;

        .avatar {
            width: 50px;
            height: 50px;
        }
    }

    .info {
        width: calc(100% - 50px);
        justify-content: unset;

        > div:first-child {
            width: 54px;

            @media (min-width: $screen-md-min) {
                width: 0;
            }
        }

        > div:last-child {
            width: calc(calc(100% - 50px));
        }
    }

    .header-options {
        width: 50px;
    }

    .username {
        padding-left: 10px;
        color: var(--ui7);
        @include truncate-singleline(calc(100% - 50px));

        .writing {
            font-size: 13px;
            font-weight: 400;
        }

        > div {
            @include truncate-singleline(calc(100%));
        }
    }

    a {
        cursor: pointer;
    }

    mp-actionbar-message-receive {
        display: flex;
        align-items: center;

        .personal-link {
            position: relative;

            .icon {
                position: absolute;
                bottom: 10px;
                right: 0;
                z-index: 2;
                background: #FFF;
                border-radius: 100%;
                pointer-events: none;

                @media (min-width: $screen-md-min) {
                    bottom: 0;
                }

                img {
                    width: 8px;
                    height: 8px;
                    margin: 3px;
                    display: block;

                    @media (min-width: $screen-md-min) {
                        width: 12px;
                        height: 12px;
                        margin: 5px;
                    }
                }
            }

            .online-tip {
                position: absolute;
                right: 0;
                top: 15px;
                color: transparent;
                overflow: hidden;
                border: solid 2px var(--ui1);
                width: 9px;
                height: 9px;
                pointer-events: none;

                @media (min-width: $screen-md-min) {
                    top: 0;
                    width: 14px;
                    height: 14px;
                    border-color: #333;
                }
            }
        }
    }
}

.profile-header {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    background-color: transparent;
}

.bubble-list {
    height: calc(100vh - 110px);

    @media (min-width: $screen-md-min) {
        height: calc(100vh - 310px);
    }

    &__wrapper {
        display: flex;
        flex-direction: column-reverse;
        min-height: 100%;
        position: relative;
    }

    &__container {
        padding: 0px 15px;
        padding: 0px 15px env(safe-area-inset-bottom);
        padding-top: 10px;
        overflow-x: hidden;

        @media (min-width: $screen-md-min) {
            padding: 30px 20px 0;
        }
    }

    &--faker {
        padding-bottom: 60px;
        margin-bottom: 60px;
    }

    .total-padding {
        background-color: $currentConversation;

        @media (min-width: $screen-md-min) {
            background-color: transparent;
        }
    }

    &--items {
        width: 100%;
        display: block;
    }

    li {
        list-style: none;
        margin-bottom: 1px;

        .content .detail {
            font-size: 11px;
            line-height: 1.36;
            height: 0;
            font-weight: 500;
            color: #BBBBBB;
            overflow: hidden;
            transition: height .25s ease-out;
            margin: 1px 2px 0 0;
            letter-spacing: normal;

            .chat-new-msg-loader {
                position: relative;
                top: 2px;
                margin-left: auto !important;
            }
        }

        &.first-in-group {
            &.chatting-person .bubble {
                border-top-left-radius: 18px;
            }

            &.logged-user .bubble {
                border-top-right-radius: 18px;
            }
        }

        &.last-in-group {
            &.chatting-person .bubble {
                border-bottom-left-radius: 18px;
            }

            &.logged-user {
                .bubble {
                    border-bottom-right-radius: 18px;
                }
            }

            &.logged-user .content-inner .read-message-flag {
                display: none;
            }

            .detail {
                height: 15px;
                margin-bottom: 5px;
                display: block;
            }
        }
    }

    .content {
        .content-inner {
            max-width: 90%;
            display: flex;
        }

        &.show-detail {
            .content-inner .read-message-flag {
                display: none;
            }

            .detail {
                display: block;
                height: 15px;
                margin-bottom: 5px;
            }
        }
    }

    .bubble {
        min-width: 36px;
        font-size: 14px;
        line-height: 1.5;
        max-width: 100%;
        align-items: center;
        padding: 8px 12px;
        white-space: pre-wrap;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    li.chatting-person {
        &:not(.last-in-group) .content.show-detail .bubble {
            background: #BBB;
        }

        .bubble {
            background-color: #DDD;
            color: var(--ui7);
            border-radius: 4px 18px 18px 4px;

            &:hover {
                background-color: darken(#DDD, 5%);
            }

            &-wrapper {
                overflow: hidden;
            }

            &.writing {
                background: transparent;
                padding-left: 0;
                animation: writing-anim 0.75s;
            }
        }
    }

    li.logged-user {
        text-align: right;

        .content .content-inner {
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .read-message-flag {
                margin-bottom: 10px;
                margin-right: 5px;
            }
        }

        &:not(.last-in-group) .content.show-detail .bubble {
            background-color: var(--fleurDeLis);
        }

        .bubble {
            background-color: var(--fleurDeLis);
            color: var(--ui1);
            border-radius: 18px 4px 4px 18px;

            &:hover {
                background-color: var(--fleurDeLis);
            }

            &:active {
                background-color: var(--fleurDeLis);
            }
        }
    }
}

@keyframes writing-anim {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: none;
    }
}

footer.write-new-message {
    background-color: var(--ui1);
    transform: translate3d(0, 0, 0);
    position: fixed;
    left: 0;
    z-index: 99;
    bottom: 0;
    padding: 10px 0;
    padding-bottom: calc(10px + env(safe-area-inset-bottom));

    &.profile-unavailable {
        background: var(--ui1);
        color: #707070;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 13px;
        line-height: 18px;
        padding-top: 0;
        border-top: 1px solid #DDDDDD;

        div {
            padding: 0 10px;
            max-width: 370px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: $screen-md-min) {
                max-width: 420px;
                height: 79px;
            }
        }

        @media (min-width: $screen-md-min) {
            font-size: 15px;
            line-height: 20px;
            padding-bottom: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .text-space {
        width: calc(100% - 40px);
        padding: 0 10px;
    }

    .button-space {
        width: 30px;
    }

    .row {
        display: flex;
        align-items: flex-end;
    }

    .warning {
        color: #BBBBBB;
        font-size: 9px;
        padding: 0 10px;
        align-items: center;
        height: 20px;

        div {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        @media (min-width: $screen-md-min) {
            justify-content: center;
            font-size: 11px;
        }
    }

    textarea {
        padding: 5px 10px;
        min-height: 30px;
        max-height: 114px;
        outline: none;
        border: none;
        @include border-radius(15px);
        vertical-align: top;
        background-color: var(--ui3);
        font-size: 14px;
        color: var(--ui7);
    }

    button {
        width: 30px;
        height: 30px;
        background-image: linear-gradient(var(--fleurDeLis), var(--galaxyBlue));
        color: var(--ui1);
        border-radius: 50%;
        border: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-image: linear-gradient(var(--fleurDeLis), var(--galaxyBlue));
            color: var(--ui1);
        }

        &:focus {
            background-image: linear-gradient(var(--fleurDeLis), var(--galaxyBlue));
            color: var(--ui1);
        }

        &:active {
            background-color: #000;
            color: var(--ui1);
        }

        i {
            font-size: 18px;
        }
    }
    @media (min-width: $screen-md-min) {
        padding: 15px 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 4px;
    }
}

.chat-wrapper {
    .scroll-to-bottom {
        position: fixed;
        bottom: 10px;
        bottom: calc(10px + env(safe-area-inset-bottom));
        left: 50%;
        transform: translateX(-50%);
        background: var(--ui1);
        color: $fleur-de-lis;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        padding: 0;
        border: 0;
        border-radius: 999px;
        font-size: 15px;
        z-index: 3;
        display: flex;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
        margin-bottom: 60px;

        .material-icons {
            font-size: 22px;
            margin: 7px;
        }

        span {
            display: inline-block;
            line-height: 1;
            font-weight: 600;
            padding-right: 13px;
        }

        @media (min-width: $screen-md-min) {
            position: absolute;
            bottom: 10px;
            margin-bottom: 80px;
        }
    }

    @media (min-width: $screen-md-min) {
        min-height: 0;
        margin-bottom: 0;
        height: calc(100% - 160px);
    }
}

@media (min-width: $screen-md-min) {
    .chat-flex {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        .placeholder-area {
            padding-bottom: 80px;
        }
    }
}

.chat-container {
    width: 100%;

    @media (min-width: $screen-md-min) {
        overflow-y: auto;
        max-height: 100%;
    }
}

.chat-header-wrapper {
    @media (max-width: $screen-sm-max) {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 1000;
        padding-top: env(safe-area-inset-top);
    }
}

.chat-new-msg-loader {
    @include loader(1em);
    margin: 0 !important;
}

.old-msg-loader {
    @include loader(34px);
    margin: 0 auto 10px !important;
}

.btn.btn-old-msgs {
    margin: 0 auto 10px;
    display: block;
    background-color: #BBBBBB;
    color: var(--ui1);
}
