.explanation-modal {
    text-align: center;

    &.dial-box {
        position: relative;
    }

    .title {
        font-size: 24px;
        margin-top: 20px;
        line-height: 1.21;

        .medium {
            display: block;
            width: 250px;
            margin: 0 auto;

            @media (min-width: $screen-md-min) {
                width: auto;
                font-size: 30px;
            }
        }

        .large {
            display: block;
            font-size: 32px;

            @media (min-width: $screen-md-min) {
                font-size: 36px;
            }
        }
    }

    .rounded-container {
        display: flex;
        flex-flow: nowrap row;
        justify-content: center;
    }

    .rouded-pics {
        margin: 20px 15px 20px;
        display: block;
    }

    .explanation-box {
        margin: 0 auto;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 430px;
        }
    }

    .explanation-text:first-child {
        padding-bottom: 0;
    }

    .explanation-text {
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        line-height: 1.44;
        padding-bottom: 20px;
    }

    .close-button {
        background: transparent;
        border: 0;
        color: #BBB;
        font-size: 25px;
        line-height: 1;
        padding: 0;
        position: absolute;
        right: 20px;
        top: 20px;

        i {
            display: block;
        }
    }

    .mp-main-button {
        margin: 20px auto;
        display: block;
        width: 170px;
    }
}
