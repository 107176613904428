%boost-container-common {
    position: relative;
    height: calc(100vh - 70px);
    overflow: hidden;

    @media (max-width: $screen-sm-max) {
        height: calc(100vh - 50px);
    }
}

%cloud-background-common {
    min-height: 100vh;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    transition: all .6s ease-in-out;
    position: relative;
    top: 0;
}

%cloud-background--full-background {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 100%;
    background-color: #FFF;
}

%cloud-background--full-width {
    width: 100vw;
    background: transparent url('../img/cloud.svg') repeat-x;
    height: 100px;
    flex-shrink: 0;
    background-position-y: 30px;
}
