.payment-modal-header {
    margin-top: 0;

    &__title {
        font-size: 26px;
        font-weight: 700;
        font-family: 'Montserrat';
    }

    &__price-total {
        font-size: 18px;
    }
}
