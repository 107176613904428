.submenu-list {
    position: fixed;
    left: 0;
    top: 60px;
    width: 100%;
    height: 50px;
    padding: 11px 0 10px 0;
    z-index: 9;
    background-color: var(--ui1);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none;

    &__grid-buttons {
        display: flex;
        padding: 0 10px 0 0;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &__navigation-list {
        border-bottom: 0;
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        margin-bottom: 0;
    }

    &__item {
        &:last-child {
            padding-right: 10px;
        }

        &:not(.active) {
            .bubbles__icon {
                display: none;
            }
        }
    }
}

.submenu-v2.conversation {
    margin-bottom: 25px;

    .submenu-list {
        position: fixed;
        padding-bottom: 10px;
    }
}

.selected i {
    background-image: linear-gradient(to bottom, var(--fleurDeLis), var(--minsk));
}

.color-icon {
    background-color: var(--ui1);
    border: none;
    width: 22px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(2) {
        margin-left: 10px;
    }
}
