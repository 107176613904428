.toast-top-right {
    top: 0;
    left: 0;
    right: 0;

    @media (min-width: $screen-md-min) {
        top: 80px;
        top: calc(80px + env(safe-area-inset-top));
        left: auto;
        right: 20px;
        right: calc(20px + env(safe-area-inset-right));
    }
}

.toast-title {
    word-wrap: break-word;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    padding-top: 1px;
}

.toast-message,
.toast-icon {
    word-wrap: break-word;
    font-size: 14px;
    line-height: 19px !important;
    padding-top: 3px;
    padding-bottom: 1px;
}

.toast-icon {
    font-size: 19px !important;
    margin-right: 0.3em;
}

.toast-close-button {
    position: absolute;
    font-size: 25px;
    font-weight: bold;
    color: #999;
    width: 40px;
    height: 40px;
    right: env(safe-area-inset-right);
    top: env(safe-area-inset-top);

    @media (min-width: $screen-md-min) {
        right: 0;
        top: 0;
    }
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #222;
    text-decoration: none;
    cursor: pointer;
}

button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .toast {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
    color: #222;
    padding: 23px 15px;
    padding: calc(23px + env(safe-area-inset-top)) calc(15px + env(safe-area-inset-right)) 23px calc(15px + env(safe-area-inset-left));
    width: 100%;

    @media (min-width: $screen-md-min) {
        width: 300px;
        border-radius: 4px;
        padding: 10px 10px 14px;
    }
}

.toast {
    background-color: #FFF;
    pointer-events: auto;
    cursor: pointer;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    border-radius: 4px;
    background-color: #666;

    &.custom-toast {
        background-color: var(--radRed);
    }
}

.toast-avatar {
    position: relative;
    width: 44px;
    height: 44px;
    margin-right: 10px;
    background: #777;
    border: 2px solid #FFF;
    border-radius: 100%;
    z-index: 2;

    & + .toast-avatar {
        margin-left: -44px;
        z-index: 1;
    }
}
