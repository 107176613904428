.boost-onboarding-promote {
    &__icons {
        .boost-onboarding-promote__img {
            position: absolute;
            @include border-radius(50%);

            &--position0 {
                height: 43px;
                width: 43px;
                top: 57px;
                left: 37px;
            }

            &--position1 {
                height: 33px;
                width: 33px;
                top: 133px;
                left: 80px;
            }

            &--position2 {
                height: 57px;
                width: 57px;
                top: 144px;
                left: 11px;
            }

            &--position3 {
                height: 40px;
                width: 40px;
                top: 222px;
                left: 64px;
            }

            &--position4 {
                height: 40px;
                width: 40px;
                top: 26px;
                right: 88px;
            }

            &--position5 {
                height: 36px;
                width: 36px;
                top: 94px;
                right: 21px;
            }

            &--position6 {
                height: 24px;
                width: 24px;
                top: 157px;
                right: 76px;
            }

            &--position7 {
                height: 52px;
                width: 52px;
                top: 197px;
                right: 21px;
            }

            &--position1,
            &--position6 {
                opacity: 0.7;
                -webkit-filter: blur(0.8px);
                filter: blur(0.8px);
            }

            @media (max-width: $screen-xbs-max) {
                &--position0 {
                    height: 27px;
                    width: 27px;
                    top: 28px;
                    left: 14px;
                }
                
                &--position1 {
                    height: 23px;
                    width: 23px;
                    top: 74px;
                    left: 40px;
                }
                
                &--position2 {
                    height: 38px;
                    width: 38px;
                    top: 108px;
                    left: 0px;
                }
                
                &--position3 {
                    height: 30px;
                    width: 30px;
                    top: 162px;
                    left: 44px;
                }

                &--position4 {
                    height: 40px;
                    width: 40px;
                    top: 15px;
                    right: 48px;
                }
        
                &--position5 {
                    height: 26px;
                    width: 26px;
                    top: 74px;
                    right: 0px;
                }
        
                &--position6 {
                    height: 18px;
                    width: 18px;
                    top: 115px;
                    right: 36px;
                }
        
                &--position7 {
                    height: 42px;
                    width: 42px;
                    top: 147px;
                    right: 11px;
                }
            }
        }
    }
}
