.edit-profile {
    background: linear-gradient(#222, #222 150px, transparent 150px);
    min-height: 150px;
    padding: 10px 5px;

    @media (min-width: $screen-md-min) {
        background: linear-gradient(#222, #222 240px, transparent 240px);
        min-height: 240px;
        padding: 20px 0;
    }

    .container {
        max-width: 570px;
        padding: 20px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        background: #FFF;
        border-radius: 4px;

        @media (min-width: $screen-md-min) {
            padding: 30px 40px;
        }
    }

    .page-title {
        font-size: 22px;
        line-height: 1.36;
        margin: 0 0 -30px;
    }

    .title {
        margin: 30px 0;

        .text-link {
            color: $fleur-de-lis;
            font-size: 13px;
        }
    }

    .violated-terms {
        position: relative;
        top: -30px;
    }

    h4.title {
        font-size: 20px;
    }

    .warning {
        margin-top: 20px;
        font-size: 13px;
        line-height: 1.38;

        .text-link {
            font-weight: 700;
            font-size: 13px;
            color: inherit;
        }
    }

    .thanks-msg {
        p {
            font-size: 18px;

            @media (min-width: $screen-md-min) {
                &:first-child {
                    font-size: 22px;
                }
            }
        }
    }
}

.static-username {
    margin-top: 8px;
}
