.profiles-container {
    .users {
        display: flex;
        height: auto;
        gap: 1rem;
    }

    &--list {
        .users {
            flex-direction: column;
            display: flex;
            width: 100%;
            gap: 1rem;
            margin-bottom: 1rem;
        }
    }
}

.user-list {
    padding: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
    }

    .list-view-info {
        display: none;
    }

    @media (max-width: $screen-xs-max) {
        margin-left: -5px;
        margin-right: -5px;
    }

    &.list-view-mode {
        .user-list-person {
            width: 100%;
        }
    }

    .melt {
        .user-list-person {
            min-width: 33%;
        }
    }

    .user-list-person {
        width: 50%;
        float: left;
        list-style: none;
        padding: 0 2.5px;
        margin-bottom: 10px;
        position: relative;

        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            width: 25%;
        }

        @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
            width: calc(100% / 3);
        }

        @media(min-width: $screen-lg-min) {
            width: 25%;
        }

        @media(min-width: 1500px) {
            width: 20%;
        }

        .baby-highlight {
            width: 100%;
            height: 10px;
            background: linear-gradient(to right, #FF4D43, #E00C00);
            border-radius: 4px 4px 0 0;
        }

        .list-view-mode-content {
            @include boxShadow(0, 0, 4px, 0, 0, 0, 0, 0.1);
            background-color: var(--ui1);
            padding: 10px;
            display: flex;
            position: relative;
            border-radius: 4px;

            &.skeleton {
                background: #E3E3E3;
                box-shadow: none;
            }

            .profile-link {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
            }

            .avatar {
                border-radius: 4px;
                width: 220px;
                height: 220px;
                flex: none;
            }

            .list-view-mode-details {
                margin: 10px 20px 0;
                padding-top: 10px;
                flex: 1;
                min-width: 0;
            }

            .username {
                display: flex;
                align-items: center;

                .title {
                    @include truncate-singleline(auto);

                    &.skeleton-item {
                        width: 75%;
                        height: 1.2em;
                        margin-bottom: 10px;
                    }
                }

                .online-tip {
                    position: static;
                    flex: none;
                    margin-left: 10px;
                }
            }

            .location {
                font-size: 18px;
                line-height: 1.33;
                margin-bottom: 10px;
                @include truncate-singleline(auto);

                &.skeleton-item {
                    width: 50%;
                }
        }

            .headline {
                @include truncate-singleline(auto);


                &.skeleton-item {
                    width: 60%;
                }

                &:not(.skeleton-item) {
                    &::before {
                        content: '\201C';
                    }

                    &::after {
                        content: '\201D';
                    }
                }
            }

            .badges {
                margin-top: 10px;
                flex: none;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-size: 12px;

                li {
                    height: 26px;
                    border-radius: 26px;
                    margin-bottom: 5px;

                    &.skeleton-item {
                        &:first-child {
                            width: 120px;
                        }

                        &:last-child {
                            width: 80px;
                        }
                    }
                }

                .melt-badge {
                    margin-bottom: 11px;
                }
            }

            .photos {
                position: absolute;
                bottom: 10px;
                left: 250px;
                display: flex;

                img,
                .title,
                .skeleton-item {
                    width: 50px;
                    height: 50px;
                    margin-right: 5px;
                }

                .title {
                    font-size: 18px;
                    line-height: 50px;
                }

                .skeleton-item-pill {
                    height: 30px;
                    margin-top: 10px;
                }
            }

            .actions {
                position: absolute;
                bottom: 10px;
                right: 10px;
                min-width: 44px;

                a {
                    text-decoration: none;
                }

                .action-btn {
                    width: 44px;
                    height: 44px;
                    border-radius: 4px;
                    border: 0;
                    padding: 0;
                    margin-top: 10px;
                    font-size: 12px;
                    font-weight: 600;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 2;

                    &:not(.skeleton-item) {
                        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.21);
                        background-color: var(--ui1);
                    }

                    .material-icons {
                        font-size: 20px;
                    }

                    &.btn-red {
                        background-color: $fleur-de-lis;
                        color: var(--ui1);
                    }

                    .active {
                        color: $fleur-de-lis;
                    }

                    @media (min-width: $screen-lg-min) {
                        width: 120px;
                    }
                }
            }
        }

        .baby-highlight-border {
            border-radius: 0 0 4px 4px;
            border-left: 1px solid $fleur-de-lis;
            border-bottom: 1px solid $fleur-de-lis;
            border-right: 1px solid $fleur-de-lis;
        }

        .aspect-ratio {
            @include aspect-ratio(1px, 1px, 35px);
            background-color: #E3E3E3;
            @include border-radius(5px);
            overflow: hidden;

            @include can-hover {
                @include aspect-ratio(1px, 1px);
            }

            .avatar {
                width: 100%;
                height: calc(100% - 35px);
                object-fit: cover;

                @include can-hover {
                    height: 100%;
                }
            }
        }

        .piclink {
            display: block;
            width: 100%;
            position: absolute;
            height: calc(100% - 35px);
            top: 0;
            left: 0;
            z-index: 3;
            cursor: pointer;

            div {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }

        .main-link {
            height: 100%;
            width: 100%;
            @include border-radius(5px);
            overflow: hidden;
            background-color: #E3E3E3;
            display: block;
            position: relative;

            .info {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }

        .category {
            margin-bottom: -3px;

            > div {
                display: flex;
                align-items: flex-end;
            }

            label {
                font-weight: 700 !important;
                font-size: 12px;
                text-transform: uppercase;
                color: $mp-gold;
            }

            .icon {
                width: 14px;
                height: auto;
                margin: 0 6px 6px auto;
                top: 0;
                bottom: 0;
            }

            &.search-category {
                background: black;
                display: inline-block;
                padding: 2px 12px;
                border-radius: 24px;
                vertical-align: middle;
                margin-right: 10px;
            }

        }

        .online-tip {
            position: absolute;
            left: 10px;
            top: 16px;
        }

        .attributes-list {
            padding: 0;
            margin-top: 5px;
            li {
                width: 100%;
                height: 26px;
                float: none;
                margin-bottom: 3px;
                margin-top: 0;
                padding: 0;
                position: relative;

                img {
                    position: absolute;
                    right: 5px;
                    width: 26px;
                    height: 26px;
                    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .15);
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .15);
                    border-radius: 50%;
                }

                &.changed-attribute {
                    margin-bottom: 14px;

                    img {
                        width: auto;
                        height: auto;
                        box-shadow: none;
                        border-radius: unset;
                    }
                }
            }
        }

        .bottom {
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 100%;

            .personal-info {
                display: flex;
                align-items: flex-end;
                padding: 10px 10px 45px 10px;
                color: var(--ui1);
                height: 100%;
                margin-bottom: -35px;

                &:not(.skeleton) {
                    background: linear-gradient(transparent, transparent calc(35% - 35px), #000 calc(100% - 35px));
                }

                > div {
                    width: 100%;
                }

                h4 {
                    margin: 0;
                    font-weight: 700;
                    font-size: 15px;
                    @include truncate-singleline(calc(100%));
                    padding-bottom: 2px;
                }

                h6 {
                    margin: 1px 0 0;
                    @include truncate-singleline(calc(100%));
                    padding-bottom: 1px;

                }

                .pics-count {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 18px;
                        margin-right: 5px;
                    }

                    div {
                        font-size: 14px;
                    }
                }

                .skeleton-pics {
                    font-size: 20px;
                    margin-bottom: 6px;
                }

                .skeleton-username {
                    font-size: 12px;
                    margin-bottom: 6px;
                }

                .skeleton-location {
                    font-size: 8px;
                    width: 80%;
                }
            }

            .action {
                height: 35px;
                width: 100%;

                &.skeleton {
                    background: #d9d9d9;
                }

                a {
                    width: 50%;
                    height: 35px;
                    text-align: center;
                    display: block;
                    float: left;
                    color: #CCC;

                    i {
                        font-size: 18px !important;
                        line-height: 35px;

                        &.active {
                            color: $fleur-de-lis;
                        }
                    }

                }
            }
        }

        @include can-hover {
            .bottom {
                bottom: -35px;
                -webkit-transition: bottom .2s ease;
                -moz-transition: bottom .2s ease;
                -ms-transition: bottom .2s ease;
                -o-transition: bottom .2s ease;
                transition: bottom .2s ease;
            }

            &:hover {
                .bottom:not(.skeleton) {
                    bottom: 0;
                }
            }
        }
    }

    @media(min-width: $screen-md-min) {
        &.list-view {
            margin-left: 0;
            margin-right: 0;
            li {
                width: 100% !important;
                padding-bottom: 10px;
                border-bottom: 1px solid #DEDEDE;
                overflow: visible;
                position: relative;

                &:first-child {
                    margin-top: 40px;
                }

                .main-link {
                    height: 228px;
                    width: 110px;
                    border-radius: 0;
                    overflow: hidden;
                    background-color: var(--profileImageBackground);
                    display: block;
                    position: absolute;

                    img {
                        height: 194px;
                        width: 110px;
                    }

                    .info {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }

                    .attributes-list, .category, .personal-info {display: none;}
                }

                .attributes-list {
                    float: right;
                    margin-top: 5px;
                    li {
                        display: block;
                        width: 26px !important;
                        height: 26px;
                        float: left;
                        margin-bottom: 3px;
                        margin-top: 0;
                        padding: 0;
                        position: relative;
                        border: none;
                        margin-left: 10px;
                    }
                }

                .list-view-info {
                    a {
                        display: block;
                        color: inherit;
                        &:hover .col-md-8 {
                            text-decoration: underline;
                        }
                    }
                    display: block;
                    height: 228px;
                    padding-left: 130px;

                    .details {
                        margin-top: 20px;

                        .item:last-child {
                            margin-top: 20px;
                        }
                    }
                }

                .bottom {
                    height: auto;

                    .action {
                        @include border-radius(0 0 4px 4px);
                    }

                }
            }
        }
    }
}

.online-tip {
    width: 7px;
    height: 7px;
    background-color: #4FD463;
    border-radius: 50%;
}

.container.users-list-container {
    margin-top: 60px;

    &--baby {
        margin-top: 0;
    }
}

.skeleton-heading {
    width: 195px;
    height: 22px;
}

.blog-posts-list {
    margin-top: 20px;

    li {
        background-color: var(--ui1);
        @include boxShadow(0, 0, 5px, 0, 0, 0, 0, .10);
        @include border-radius(8px);
        margin-bottom: 10px;

        &:hover {
            @include boxShadow(0, 0, 15px, 4px, 0, 0, 0, .12);

            a {
                text-decoration: none;
            }
        }

        .blog-post {
            display: block;
            padding: 15px 20px;
            color: $mp-main-dark;
        }

        h1 {
            margin: 10px 0;
            font-size: 18px;
            font-weight: 700;
        }

        .skeleton-title-1 {
            width: 195px;
            font-size: 16px;
            margin: 10px 0 2px;
        }

        .skeleton-title-2 {
            width: 125px;
            font-size: 16px;
            margin-bottom: 12px;
        }

        .skeleton-paragraph {
            font-size: 8px;
            margin-bottom: 8px;
        }

        .skeleton-paragraph-last {
            width: 247px;
            font-size: 8px;
            margin-bottom: 15px;
        }

        p {
            margin: 0;
            font-size: 13px;
        }
        h6 {
            margin: 0 0 5px;
        }
    }
}

.lds-css {
    @include loader(60px);

    @media (max-width: $screen-md-max) {
        position: fixed;
        left: 50%;
        margin-left: -30px;
        top: 50%;
        margin-top: -30px;
    }
}

.mobile-refresh {
    display: block;
    margin: auto;
    text-align: center;
    width: 120px;
    background-color: red;
    color: var(--ui1);
    height: 33px;
    border-radius: 17px;
    font-size: 14px;
    line-height: 33px;
    border: 0;
    padding: 0;
    position: relative;
    top: -10px;
    z-index: 1;

    i.material-icons {
        vertical-align: middle;
        font-size: 20px;
        margin-left: 10px;
        margin-top: -2px;
    }
}

.upgrade-status {
    display: block;
    margin-bottom: 30px;

    .box {
        @include border-radius(12px);
        text-align: center;
        position: relative;
        margin: auto;
        max-width: 100%;
        min-height: 120px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: $fleur-de-lis;
            text-decoration: underline;
        }

        p {
            width: 100%;
        }

        img.icon-badge {
            width: 44px;
            height: 44px;
        }

        .title {
            font-weight: 700;
            font-size: 20px;
        }

        p {
            margin-bottom: 0;
        }

        button {
            background: transparent;
            border: none;
            width: 28px;
            height: 28px;
            position: absolute;
            right: 10px;
            padding: 0;
            top: 10px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .btn-cta {
            margin-top: 10px;
            display: inline-block;
            background: $fleur-de-lis;
            color: var(--ui1);
            font-weight: 700;
        }

        &.bg-white {
            @include boxShadow(0, 0, 5px, 0, 0, 0, 0, .1);
            background-color: var(--ui1);
            padding: 15px;
            z-index: 1;
        }
    }
}

.list-layout {
    margin: 15px 0;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 600;
    height: 16px;
    color: #999;

    .list-layout-item {
        position: relative;
        margin-left: 20px;
        display: flex;

        input {
            margin: 0;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            cursor: pointer;

            &:checked ~ * {
                color: var(--ui7);
            }

            &.focus-visible:focus ~ label {
                box-shadow: 0 0 0 2px;
            }
        }

        label {
            margin: 0;
            line-height: 16px;
        }

        .symbol {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            position: relative;

            &.symbol-list-layout {
                width: 20px;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                }

                &::before {
                    background: currentColor;
                    top: 6px;
                    left: 0;
                    right: 0;
                    height: 4px;
                }

                &::after {
                    border: solid;
                    border-width: 4px 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            &.symbol-grid-layout {
                &::before,
                &::after {
                    content: '';
                    background: currentColor;
                    position: absolute;
                    left: 0;
                    width: 7px;
                    height: 7px;
                    box-shadow: 9px 0;
                }

                &::before {
                    top: 0;
                }

                &::after {
                    bottom: 0;
                }
            }
        }
    }
}

.list-header {
    width: 100%;
    color: var(--ui7);
    margin-bottom: 15px;

    .title {
        font-size: 18px;
        line-height: 22px;
        font-family: 'Montserrat';
    }

    .subtitle {
        font-size: 14px;
        line-height: 18px;
        font-family: 'OpenSans';
    }
}
