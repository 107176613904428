.boost-payment-modal {
    width: 100%;

    @media (min-width: $screen-sm-min) {
        max-width: 414px;
    }

    &__form {
        margin-top: 30px;
    }
}
