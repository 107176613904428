.boost-activate-success {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    max-width: 390px;
    margin: auto;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        &__title {
            font-family: 'Montserrat';
            font-size: 26px;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: 0.3px;
        }

        .boost-icon {
            width: 0;
            height: 0;
            transition: width .6s ease-in-out, height .6s ease-in-out;

            &--show-up {
                width: 30px;
                height: 30px;
                transition: width .6s ease-in-out, height .6s ease-in-out;
            }
        }
    }

    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        &__buttons {
            width: 100%;
            margin-top: 10px;
        }

        &__post-scriptum {
            margin-top: 30px;

            @media (max-width: $screen-xs-min) {
                align-self: flex-start;
            }
        }
    }
}
