@import 'config.scss';

.badges-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
}

.new-badge {
    color: var(--ui7);
}

.verified-badge {
    color: var(--ui7);
}

.prince-badge {
    background: linear-gradient(to right, var(--galaxyBlue), #7E57FF);
    font-weight: normal;
    color: var(--ui1);

    img {
        width: 18px;
        margin: 4px;
    }
}

.highlight-badge {
    background: linear-gradient(to right, var(--radRed) 2%, #FB7D4E);
    font-weight: normal;
    color: var(--ui1);

    img {
        width: 18px;
        margin: 4px;
        margin-left: 2px;
    }
}

.melt-badge {
    background-color: unset;
    padding: 0;

    img {
        width: auto;
        margin: 0;
    }
}

.elite-badge {
    font-weight: normal;
    background: linear-gradient(to right, #8B724F 2%, #B29252);
    color: #FFF;

    img {
        width: 18px;
        margin: -3px 6px 0 4px;
    }
}

.profile-badge {
    height: 26px;
    font-size: 13px;

    p {
        margin-top: -1px;
        margin-bottom: 0;
    }
}
