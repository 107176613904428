.close-menu-container {
    display: flex;
    height: 100%;
    align-items: center;
    min-height: 100%;

    .close-menu-btn {
        width: 30px;
        height: 30px;
        border: 0;
        padding: 0;
        background-color: transparent;
        display: contents;

        .meterial-icons {
            font-size: 18px;
            color: #707070;
        }
    }
}
