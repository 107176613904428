mp-subscribed-status {
    z-index: 100;
}

.subscribed-status {
    margin-left: 20px;

    &.extended {
        margin-left: 0;
        margin-right: 0;
        margin-top: 11px;
        margin-bottom: 36px;
    }
}
