.payment-modal-form {
    .mp-main-button {
        margin-top: 20px;
    }

    .col-xs-12 {
        &:last-child {
            .form-field {
                margin-bottom: 0;
            }
        }
    }
}
