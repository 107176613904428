.boost-balance {
    font-family: 'Montserrat';
    line-height: 27px;

    > div p {
        margin: 0;
    }

    &__label {
        margin-bottom: 0;
        font-size: 22px;
        line-height: 27px;
    }

    &__balance {
        color: $boost-purple;
        font-size: 22px;
    }
}
