@use 'sass:math';

$fleur-de-lis: #7357FF;
$minsk: #3A2C80;
$mp-main-dark: #222;
$mp-gold: #FFB100;
$boost-purple: #4400BB;
$boost-purple-ligther: #5D00FF;

ul {
    padding: 0;

    li {
        list-style: none;
    }
}

.nowrap {
    white-space: nowrap;
}

.padding-top15px {
    padding-top: 15px;
}

.flex {
    display: flex;
    align-items: center;
}

.bold {
    font-weight: 700;
}

.linebottom {
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 10px;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

/*=====================================
=            Media Queries            =
=====================================*/
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-float-breakpoint: 1280px;

$screen-xbs-min: 320px;
$screen-xbs-max: 419px;

$screen-xs-min: 420px;
$screen-xs-max: 767px;

$screen-sm-min: 768px;
$screen-sm-max: 991px;

$screen-md-min: 992px;
$screen-md-max: 1199px;

$screen-lg-min: 1200px;
$screen-lg-max: 1499px;

$screen-xl-min: 1500px;

/*=======================================
=            Percentage Grid            =
=======================================*/

$wid-10perc: 10%;
$wid-33perc: 33.33%;

.wid-33perc {
    width: 33.33%;
}

.wid-50perc {
    width: 50%;
}

.wid-100perc {
    width: 100%;
}

/*===================================
=            Blur Filter            =
===================================*/

.blur-filter {
    -webkit-backdrop-filter: blur(5px);
    -moz-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

/*===========================================
=            Border radius mixin            =
===========================================*/

@mixin border-radius($length) {
    -webkit-border-radius: $length;
    border-radius: $length;
}

/*=================================================
=            Truncate single line text            =
=================================================*/
@mixin truncate-singleline($width) {
    max-width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

/*=================================================
=            Width and Height with same size      =
=================================================*/
@mixin square($length) {
    width: $length;
    height: $length;
}

/*==================================
=            Box Shadow            =
==================================*/
@mixin boxShadow($horizontal, $vertical, $blur, $spread, $RR, $GG, $BB, $AA) {
    -webkit-box-shadow: $horizontal $vertical $blur $spread rgba($RR, $GG, $BB, $AA);
    box-shadow: $horizontal $vertical $blur $spread rgba($RR, $GG, $BB, $AA);
}

/*================================
=            Arrow            =
================================*/
@mixin getArrowUp($size, $color) {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
}
@mixin getArrowLeft($size, $color) {
    width: 0;
    height: 0;
    border-bottom: 8px solid transparent;
    border-right: 8px solid $color;
    border-top: 8px solid transparent;
}

/*====================================
=            Generic list            =
====================================*/
.generic-list {
    li {
        padding: 15px 20px 15px 0;
        border-bottom: 1px solid #DEDEDE;
        background: transparent url('../img/right-chevron.svg') right center no-repeat;
        background-size: 14px;
        position: relative;

        a {
            color: $mp-main-dark;
            font-size: 18px;
            display: block;
            cursor: pointer;

            .new-feature {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    li:last-child {
        border-bottom: none;
    }
}

/*======================================
=            Generic Header            =
======================================*/
.generic-header {
    padding: 0;
    color: var(--ui7);
    width: 100%;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin: 0;
        width: 100%;

        > * {
            pointer-events: auto;
        }

        > div:first-child {
            display: flex;
            align-items: center;
        }
    }

    a {
        color: var(--ui1);
    }

    .flex {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0;

        &.flex-full {
            width: 100%;
        }
    }

    .avatar {
        @include square(30px);
        @include border-radius(50%);
        background-color: #999;
    }

    .username {
        @include truncate-singleline(calc(100% - 10px));
    }

    .dropdown {
        width: 100%;
    }
    #headerDropdown {
        background-color: transparent;
        color: var(--ui7);
        border: none;
        width: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
    }
    .dropdown-menu {
        right: 0;
        text-align: right;
        left: auto;
        width: auto;

        li {
            a {
                padding: 10px 20px;
            }
        }

        li a.dropdown-menu--option-content {
            padding: 16px 20px;
        }

        &__text-action {
            font-weight: 400;
        }

        &__icon {
            font-size: 19px;
            text-shadow: none;
            padding-left: 5px;
        }

        &--option-list {
            width: 240px;
        }

        &--option-content {
            display: flex;
            justify-content: flex-end;
        }

        &--alert {
            color: $fleur-de-lis;
        }

        &--strong {
            font-weight: 700;
        }
    }

    .text {
        font-weight: 700;
        margin: 0;
        font-size: 15px;
        @include truncate-singleline(calc(100% - 10px));
    }
}

.white-bg {
    background-color: var(--ui1);
}

.action-bar {
    width: 100vw;
}

/*============================
=            Form            =
============================*/
form.page-form {
    margin-top: 15px;

    button {
        background-color: $fleur-de-lis;
        width: 100%;
        color: var(--ui1);

        &:hover,
        &:focus {
            color: var(--ui1);
            opacity: .8;
        }
    }


}

textarea {
    resize: none;
    width: 100%;
}

.inertial {
    -webkit-overflow-scrolling: touch;
}

/*===================================
=            Aspec Ratio            =
===================================*/
@mixin aspect-ratio($width, $height, $addToBottom: 0px) {
    $result: math.div($height, $width);
    position: relative;
    &:before {
        display: block;
        content: ' ';
        width: 100%;
        padding-top: calc(#{$result} * 100% + #{$addToBottom});
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.btn.bt-backtooldversion {
    margin: 20px auto 0;
    display: block;
    background-color: #BBB;
    color: var(--ui1);
}

@mixin appearance-off {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

/*=====================================
=            Transition               =
=====================================*/
@mixin transition($attr, $time, $ease: 'linear') {
    -webkit-transition: $attr $time $ease;
    -moz-transition: $attr $time $ease;
    -ms-transition: $attr $time $ease;
    -o-transition: $attr $time $ease;
    transition: $attr $time $ease;
}

/*=====================================
=            User Select              =
=====================================*/

@mixin user-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
