.mini-users-list {
    margin-left: -15px;
    margin-right: -15px;

    .conversation-item-container, > li, .private-photos {
        position: relative;
        padding: 0 15px;

        &:last-child {
            border-bottom: none;
        }

        a {
            display: block;
            position: relative;
            cursor: pointer;
        }

        .info {
            padding-left: 60px;
            height: 70px;
            color: $mp-main-dark;
            display: flex;
            align-items: center;

            .username {
                font-weight: 700;
                @include truncate-singleline(calc(100%));
                margin: 0;
            }
        }

        .float-dropdown {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 43px;
            right: 0;

            button.btn.dropdown-toggle {
                display: flex;
                align-items: center;
                height: 43px;
                background-color: transparent;
                border: none;
            }

            ul.dropdown-menu {
                width: auto;
                right: 10px;
                left: auto;

                i {
                    vertical-align: middle;
                    font-size: 18px;
                    margin-right: 10px;
                }
            }
        }
    }

    .avatar {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 0;
        top: 10px;

        @include border-radius(50%);
    }
}
