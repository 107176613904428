.testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__list {
        position: relative;
    }

    &__title {
        @extend %montserrat;
        text-align: center;
        margin: 40px;
    }
}

.testimonial {
    position: relative;
    background-color: #FFF;
    @include border-radius(12px);
    padding: 30px 20px 20px;
    margin: 0 2px 10px;
    @include boxShadow(0, 2px, 10px, 0, 0, 0, 0, .06);

    @media (min-width: $screen-md-min) {
        margin-bottom: 30px;
        max-width: 320px;
    }

    @media (max-width: $screen-md-min) {
        margin-bottom: 30px;
    }

    &__quotation {
        padding-top: 17px;
        width: 60px;
        border-radius: 30px 30px 0 0;
        background-color: white;
        position: absolute;
        top: -14px;
        left: 7px;
        display: flex;
        justify-content: center;
    }

    &__comment {
        font-size: 16px;
        margin: 10px 0;
        z-index: 1;
        position: relative;
    }

    &__user {
        @extend %montserrat;
        font-weight: 700;
        font-size: 15px;
        margin: 0;
    }

    &__time-since {
        font-size: 14px;
    }
}
