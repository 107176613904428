:root {
    --ui1: #FFFFFF;
    --ui2: #FAFAFA;
    --ui3: #F7F7F7;
    --ui4: #F4F4F4;
    --ui5: #898989;
    --ui6: #BBB;
    --ui7: #222222;
    --ui8: #404040;
    --profileImageBackground: #DDD;
    --fleurDeLis: #7357FF;
    --minsk: #3A2C80;
    --galaxyBlue: #00004E;
    --radRed: #F94746;
    --lightGold: #FFCD82;
    --gold: #b09152;
    --sucess1: #004652;
    --sucess2: #00FFBA;
    --poppins: 'Poppins', Arial, Helvetica, sans-serif;
    --freight: freight-big-pro, 'Times New Roman', serif;
}
