.boost-activated {
    max-width: 500px;
    margin: auto;
    padding-top: 10px;

    &__footer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 0 25px;

        @media(min-width: $screen-xs-max) {
            padding: 0;
        }
    }
}
