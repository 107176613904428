.my-subscription {
    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 33px;

        img {
            width: 45px;
            margin-right: 10px;
        }

        h2 {
            margin: 0;
            line-height: 24px;
            font-size: 22px;
        }
    }

    &__membership {
        @extend %montserrat;
        font-size: 26px;
        font-weight: 700;
    }

    &__expiration-date {
        margin-top: 40px;
        color: #333;

        h4,
        p {
            font-size: 16px;
        }
    }

    &__cancellation-warning {
        margin-top: 60px;
    }
}

