$purple-hover: #8167FF;
$purple-active: #674EE5;

button[disabled], html input[disabled] {
    cursor: not-allowed;
}

.mp-main-button, a.mp-main-button {
    font-family: var(--poppins);
    font-weight: 500;
    text-transform: uppercase;
    @include border-radius(4px);
    background-color: $fleur-de-lis;
    color: #FFF;
    cursor: pointer;
    text-decoration: none;
    border: 2px solid $fleur-de-lis;
    display: inline-block;
    outline: none;

    .bt-content {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            margin-right: 5px;
        }
    }

    &:hover {
        background-color: $purple-hover;
        border-color: $purple-hover;
        color: #FFF;
    }

    &:active {
        background-color: $purple-active;
        border-color: $purple-active;
        color: #FFF;
    }

    &:visited {
        color: #FFF;
    }

    &.bt-sm {
        font-size: 16px;
        padding: 4px 15px;
    }

    &.bt-md {
        font-size: 18px;
        padding: 9px 15px;
    }

    &.bt-lg {
        font-size: 20px;
        padding: 12px 15px;
    }

    &.bt-shadow {
        box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    }

    &.outline-white {
        background-color: transparent;
        border: 2px solid #FFF;
        width: 140px;
    }

    &.white-borderless-normal {
        background-color: transparent;
        width: 140px;
        font-weight: 400;
        border: none;
    }

    &.outline-fleur-de-lis {
        background-color: transparent;
        border: 2px solid $fleur-de-lis;
        color: $fleur-de-lis;
    }

    &.outline-purple {
        background: transparent;
        border: 2px solid $boost-purple;
        color: $boost-purple;
    }

    &.white-bg {
        background-color: #FFF;
        border: 2px solid #FFF;
        color: $mp-main-dark;
    }

    &.white-bg-red-text {
        background-color: #FFF;
        color: $fleur-de-lis;
    }

    &.boost-purple-regular {
        background-color: $boost-purple;
        border: 2px solid $boost-purple;
        color: #FFF;
    }

    &.full-width {
        width: 100%;
    }

    &:disabled {
        opacity: .3;
    }

    @media (max-width: $screen-xs-max) {
        &.bt-sm-screen-xs {
            font-size: 16px;
            padding: 4px 15px;
        }

        &.bt-md-screen-xs {
            font-size: 18px;
            padding: 9px 15px;
        }

        &.bt-lg-screen-xs {
            font-size: 20px;
            padding: 12px 15px;
        }
    }

    &.call-to-action {
        margin: 0 15px;
        border-radius: 50px;
    }
}

a.text-link, button.text-link {
    color: #444;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;

    &:hover {
        color: #777;
    }
}

%btn-no-styles {
    background-color: transparent;
    border: none;
    padding: 0;
}
