@import 'loader.scss';

.loading-container {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F1F1F1;
    text-align: center;

    img {
        width: 260px;
        height: auto;
        margin: 0 auto 20px;
    }
}

.loader {
    @include loader();
}
