$defaultConversation: #FFF;
$currentConversation: #F5F5F5;
$hoverConversation: #FAFAFA;

h1.conversation {
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    padding: 20px 0 18px 20px;
}

@mixin conversation-container {
    width: 940px;

    @media (min-width: $screen-lg-min) {
        width: 1140px;
    }

    @media (min-width: $screen-xl-min) {
        width: 1420px;
    }
}

@media (min-width: $screen-md-min) {
    body.in-messages {
        padding-bottom: 0;
    }

    .conversation-bg {
        position: fixed;
        background: #222;
        left: 0;
        right: 0;
        top: 70px;
        height: 240px;
    }

    .conversation-wrapper {
        position: relative;
        top: 20px;
        left: 0;
        right: 0;
        height: calc(100vh - 110px);
        margin: 0 auto;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;

        @include conversation-container;
    }
}

.messages-list {
    position: absolute;
    width: 100%;
    top: 115px;
    bottom: 50px;
    overflow-y: auto;
    margin: 0;

    .message-item-wrapper {
        width: 285px;
        min-height: 80px;
        position: relative;
        cursor: pointer;

        .dot {
            &--is-online {
                color: #4FD463;
                position: absolute;
                z-index: 5;
                right: 0;
                top: 0;
                border: 1px solid $currentConversation;
                border-radius: 30px;
                background-color: $currentConversation;
                font-size: 10px;
            }

            &--unread-message {
                color: $fleur-de-lis;
                font-size: 10px;
                margin-left: auto;
                visibility: hidden;
                position: absolute;
                right: 10px;
                top: 36px;

                &.unread {
                    visibility: visible;
                }
            }
        }

        .message-preview,
        .message-dropdown {
            position: relative;
            width: 100%;
        }

        .message-preview {
            z-index: 4;
            height: 80px;
            padding: 12px 10px;
            display: flex;
            background-color: var(--ui4);
            left: 0;
            transition: 0.2s ease-out;

            &__photo {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 14px;
                outline: 0;

                picture {
                    img.avatar {
                        top: 0;
                        position: relative;
                    }
                }

                .user-badge {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 8;
                    border-radius: 50px;
                    width: 17px;
                }
            }

            &__info {
                width: calc(100% - 64px);
                outline: 0;

                p {
                    margin: 0;
                }

                .flex {
                    .location,
                    .message-time {
                        font-size: 12px;
                        color: #999;
                    }

                    .favorite-heart-fill {
                        color: var(--radRed);
                        font-size: 16px;
                        margin-right: 3px;
                    }

                    .username {
                        flex: 1;
                        font-size: 15px;
                        font-weight: 700;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .message-time {
                        margin-left: auto;
                        visibility: visible;
                    }

                    .location {
                        align-self: flex-start;
                    }

                    .message {
                        display: contents;

                        .read-flag {
                            color: #999;
                            font-size: 18px;
                            margin-right: 4px;

                            &.visualized {
                                color: #589BF8;
                            }
                        }

                        p {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 13px;
                            color: var(--ui7);
                        }
                    }
                }
            }

            .dropdown-toggle {
                position: absolute;
                top: 24px;
                right: 15px;
                font-size: 28px;
                display: none;
            }

            &.current,
            & ~ .message-dropdown {
                background-color: $currentConversation;
            }
        }

        .message-dropdown {
            z-index: 3;
            height: 0;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding-right: 10px;
            overflow: hidden;
            transition: 0.3s;

            &.open {
                height: 130px;
            }

            li {
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex: 1;

                i {
                    font-size: 20px;
                    padding-left: 5px;
                }

                &:hover {
                    background-color: $hoverConversation;
                }
            }

            &__delete {
                color: $fleur-de-lis;
            }

            &__favorite {
                font-weight: bold;

                i {
                    color: $fleur-de-lis;
                }
            }
        }

        .message-swipe {
            width: 100%;
            height: 80px;
            z-index: 2;
            position: absolute;
            top: 0;
            justify-content: flex-end;
            background-color: $fleur-de-lis;
            color: #fff;
            background-color: #fff;

            &.userUnavailable{
                background-color: $fleur-de-lis;

                .favorite {
                    display: none;
                }
            }

            i {
                font-size: 30px;
            }

            .delete-button,
            .favorite {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .delete-button {
                padding: 0 15px;
                background-color: var(--radRed);
                width: 50px;
                color: #fff;
            }

            .favorite {
                width: 90px;

                .favorited,
                .notfavorited {
                    position: absolute;
                }

                .favorited {
                    background: -webkit-linear-gradient($fleur-de-lis, $minsk);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .notfavorited {
                    color: $mp-main-dark;
                }

                &.isLiked {
                    .favorited {
                        animation: dropIn 0.2s;
                    }

                    .notfavorited {
                        animation: dropOut 0.2s;
                        opacity: 0;
                    }
                }

                &.notLiked {
                    .favorited {
                        animation: dropOut 0.2s;
                        opacity: 0;
                    }

                    .notfavorited {
                        animation: dropIn 0.2s;
                    }
                }
            }
        }

        @media (min-width: $screen-md-min) {
            &:hover {
                .dot--unread-message,
                .message-preview .message-time {
                    visibility: hidden;
                }

                .message-preview {
                    background-color: $hoverConversation;
                }

                .dropdown-toggle {
                    display: block;
                }
            }
        }


        @media (max-width: $screen-sm-max) {
            width: 100%;

            .message-preview {
                position: absolute;
                top: 0;
                background-color: var(--ui4);

                &:hover {
                    background-color: var(--ui4);

                    .dropdown-toggle {
                        display: none;
                    }
                }
            }

            .message-dropdown,
            .dropdown-toggle {
                display: none;
            }
        }
    }

    .conversation.expired-message {
        display: block;
        padding: 31px 0;
        text-align: center;
        font-size: 13px;
    }

    @media (min-width: $screen-md-min) {
        position: relative;
        width: auto;
        height: calc(100% - 110px);
        top: auto;
        bottom: auto;
    }
}

.side-col {
    &.invisible {
        display: none;
    }

    @media (min-width: $screen-md-min) {
        width: 285px;
        height: 100%;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
        flex-shrink: 0;
    }
}

.chat-col {
    min-width: 0;

    @media (min-width: $screen-md-min) {
        flex: 1;
        margin: 10px 10px 10px 0;
        padding: 10px;
        align-self: stretch;
        border-radius: 4px;
        background-color: $currentConversation;
    }
}

.preview {
    position: fixed;
    z-index: 101;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out;
    height: 375px;

    @include conversation-container;

    &.hovering {
        opacity: 1;
    }

    img {
        border: 20px solid $currentConversation;
        border-radius: 4px;
        box-shadow: 0 0 44px 10px rgba(0, 0, 0, 0.2);
        width: 375px;
        height: 375px;
        background-color: #EEE;
        margin-left: 285px;
    }
}

.connection-message {
    padding-left: 5px;
    background-color: #EE0000;
}

@keyframes dropIn {
    0% {
        font-size: 0;
    }

    100% {
        font-size: 30px;
    }
}

@keyframes dropOut {
    from {
        font-size: 30px;
        opacity: 1;
    }

    to {
        font-size: 40px;
        opacity: 0;
    }
}
