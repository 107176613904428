.registration-form-field {
    margin-bottom: 30px;

    > * {
        display: block;
    }

    label {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 0;
        margin-bottom: -5px;

        .material-icons.help {
            display: initial;
            margin: -8px 0 0 6px;
            padding: 0;
            font-size: 24px;
            line-height: normal;
            position: absolute;
            cursor: pointer;
        }
    }

    input, select, textarea {
        border: none;
        border-bottom: 1px solid #CCC;
        width: 100%;
        height: 40px;
        padding: 0;
        @include border-radius(0);

        &:hover {
            border-bottom: 1px solid $fleur-de-lis;
        }

        &:focus {
            border-bottom: 1px solid #000;
            outline: none;
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    select {
        @include appearance-off();
        background: url('../img/chevron-down.svg') right center no-repeat;
        background-size: 10px auto;
    }

    small {
        color: $fleur-de-lis;
        display: none;

        &.warning {
            display: block;
            color: inherit;
        }

        &.ok {
            color: #0B3;
        }
    }

    &__birthdate {
        display: flex;
        justify-content: space-between;

        select {
            width: 30%;
        }
    }

    &--feedback {
        small {
            display: block;
        }
    }
}
