.membership-badge-extended {
    color: #222;
    width: 100%;

    .user-badge-extended {
        height: 26px;
        width: 100%;
        border-radius: 13px;
        padding: 0 10px 0 4px;
        font-size: 12px;
        background-color: #FFF;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);

        p {
            font-weight: 700;
            margin-bottom: 1px;
            margin-left: 6px;
            color: #FFF;
        }

        img {
            margin-top: -1px;
        }
    }

    &__premium-badge {
        background: linear-gradient(to right, #FF4D43, #E00C00);

        img {
            width: 16px;
            height: 16px;
        }
    }

    &__elite-badge {
        background: linear-gradient(to right, #F06600, #FF9D00);

        img {
            width: 13px;
            height: 13px;
        }
    }
}
